import React, {useEffect, useState} from "react"
import "../css/ingredientSearch.css"
import PropTypes from "prop-types";
import Button from "./Button";
import useIngredients from "../hooks/useIngredients";
import {ClearInputIcon, IngredientSearchIcon, IngredientStartSearch, SearchIcon, XCircleIcon} from "../icons";
import {getIngredientSources} from "../service/IngredientsService";
import CheckboxInput from "./CheckboxInput";
import IngredientInfo from "./IngredientInfo";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import {deleteIngredient, updateIngredient} from "../service/NutricalIngredientService";
import IngredientForm from "./IngredientForm";
import {IngredientType} from "../service/IngredientService";


const Count = ({count}) => {
    return (<div className="text-light text-xs mr-4 mb-2 w-[80px]">{count} rezultata</div>)
}

const SourcesTab = ({onChange}) => {

    const [sources, setSources] = useState([])
    const [selectedSources, setSelectedSources] = useState([])

    useEffect(() => {
        getIngredientSources()
            .then(response => {
                setSources(response.data)
                setSelectedSources(response.data)
            })
    }, [])

    const toggleSource = (source) => {
        const index = selectedSources.indexOf(source)
        let newSources = [...selectedSources]

        if (index !== -1) {
            newSources.splice(index, 1)
            setSelectedSources(newSources)
        } else {
            newSources.push(source)
            setSelectedSources(newSources)
        }

        onChange(newSources)
    }

    const isChecked = (source) => selectedSources.includes(source)

    return (
        <div className="flex items-center flex-wrap">
            {
                sources.sort().map(source => (
                    <SourceCheckbox key={source}
                                    label={source}
                                    checked={isChecked(source)}
                                    onChange={() => toggleSource(source)}/>
                ))
            }
        </div>
    )
}

const SourceCheckbox = ({label, onChange, checked}) => {
    return (
        <div className="flex align-items hover:cursor-pointer hover:underline mr-6 mb-2" onClick={onChange}>
            <CheckboxInput checked={checked}/>
            <div className="text-light text-xs font-medium ml-1 flex items-center">{label}</div>
        </div>
    )
}

const Ingredient = ({ingredient, onAdd, onDelete, isValidTotal, onChange}) => {

    const [showForm, setShowForm] = useState(false)


    const handleUpdateIngredientBtn = async (ingredient, add) => {
        setShowForm(false)
        await updateIngredient(ingredient)
        onChange()
        if (add) {
            onAdd(ingredient)
        }
    }

    return (
        <tr
            className=" bg-section-header rounded-[8px] h-[54px] text-xs font-medium text-primary mb-12">
            <td className="rounded-tl-[8px] rounded-bl-[8px] text-sm pl-4">
                <div className="">
                    <span className="pr-[20px]">{ingredient.name}</span>
                    <span className="relative w-0">
                                               <span className="absolute top-0 left-[-25px]">
                                                   <IngredientInfo ingredient={ingredient}
                                                                   onAdd={() => onAdd(ingredient)}/></span>
                                           </span>
                </div>
            </td>
            <td>
                <div>{ingredient?.nutrient_basic?.energy_kj}</div>
                <div>{ingredient?.nutrient_basic?.energy_kcal}</div>
            </td>
            <td>
                <div>{ingredient?.nutrient_basic?.fat}</div>
            </td>
            <td>
                <div>{ingredient?.nutrient_basic?.carbohydrate}</div>
            </td>
            <td>
                <div>{ingredient?.nutrient_basic?.protein}</div>
            </td>
            <td>
                <div>{ingredient?.nutrient_basic?.salt}</div>
            </td>

            <td>
                <div>{ingredient?.source}</div>
                <div>
                    {isValidTotal(ingredient) && <span
                        className="text-[10px] font-semibold text-green-700">{ingredient.nutrient_total} TOTAL</span>}

                    {!isValidTotal(ingredient) && <span
                        className="text-[10px] font-semibold text-red-700">{ingredient.nutrient_total} TOTAL</span>}
                </div>
            </td>
            <td className="rounded-tr-[8px] rounded-br-[8px] w-[100px]">
                <div className="flex items-center px-5">
                    <Button color="secondary" onClick={() => onAdd(ingredient)}>
                        + Izaberi
                    </Button>
                    { ingredient.ingredient_type === IngredientType.NUTRIKATOR &&
                        <Menu position="right">
                            <MenuItem onClick={() => setShowForm(true)}
                                      startIcon={<img src="/edit.svg"/>}>Izmeni</MenuItem>
                            <MenuItem onClick={() => onDelete(ingredient)}
                                      startIcon={<img src="/delete-red.svg"/>}>Obriši</MenuItem>
                        </Menu>
                    }
                </div>
            </td>
            {showForm && <IngredientForm onUpdate={handleUpdateIngredientBtn}
                                         onUpdateAndAdd={(ingredient) => handleUpdateIngredientBtn(ingredient, true)}
                                         defaultValue={ingredient}
                                         onClose={() => setShowForm(false)}/>}
        </tr>
    )
}

const IngredientSearch = props => {

    const {onClose, recipe, updateRecipe, refreshNutricalValues, refreshRecipe} = props
    const [ingredients, setIngredients, isLoading, error, fetch, fetchSuccess] = useIngredients()
    const [params, setParams] = useState({
        searchText: "",
        sources: []
    })

    const handleParamChange = (name, value) => {
        let localParams = {
            ...params,
            [name]: value
        }

        setParams(localParams)

        if (params.searchText) {
            fetch(localParams)
        }
    }

    const handleAddBtn = (ingredient) => {
        // adds ingredient to recipe
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setIngredients(null)
        setParams({
            ...params,
            searchText: ""
        })

        let recipeLocal = Object.assign({}, recipe)
        recipeLocal.ingredients.push({
            "ingredient_type": ingredient.ingredient_type,
            "ingredient": {
                "id": ingredient.id,
                "name": ingredient.name,
            },
        })

        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: true,
            delay: 0
        })
    }


    const handleRemoveTagClick = (ingredient) => {
        // removes ingredient from recipe
        let recipeLocal = Object.assign({}, recipe);
        recipeLocal.ingredients = recipeLocal.ingredients.filter(i => i.id !== ingredient.id)

        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: false,
            delay: 0
        })

        refreshNutricalValues(recipeLocal)
    }


    const handleDeleteManuItemBtn = async (ingredient) => {
        await deleteIngredient(ingredient.id)
        setIngredients(ingredients.filter(i => i.id !== ingredient.id))
    }

    const handleIngredientChange = async () => {
        fetch(params)
        const recipeLocal = await refreshRecipe()
        refreshNutricalValues(recipeLocal)
    }


    const isValidTotal = (ingredient) => ingredient.nutrient_total > 95 && ingredient.nutrient_total < 105

    return (
        <div className="search-container
                        bg-white rounded-[12px] p-[8px] relative">
            <div className="grow-0 sticky top-0 z-10">
                <div className="relative">
                    <div className="absolute top-[12px] left-[10px]"><SearchIcon className="fill-primary-icon"
                                                                                 width={16}/></div>
                    <input className="hide-clear-btn w-full h-[42px] bg-input rounded-[6px] pl-[40px] pr-[10px] text-sm"
                           type="search" value={params.searchText}
                           onChange={(e) => handleParamChange("searchText", e.target.value)}
                           placeholder="Pretraži..."/>
                    <ClearInputIcon className="absolute right-[12px] top-[14px] hover:cursor-pointer"
                                    onClick={() => handleParamChange("searchText", "")}/>
                </div>
                <div className="flex items-center mt-4 mb-4 flex-wrap">
                    <Count count={ingredients ? ingredients.length : 0}/>
                    <SourcesTab onChange={(value) => handleParamChange("sources", value)}/>
                </div>
            </div>
            {
                (!ingredients || ingredients?.length === 0) &&
                <div className="flex flex-col justify-center items-center">
                    <div className="grow flex flex-col items-center justify-center relative">
                        <IngredientSearchIcon/>
                        <div className="absolute left-[137px] top-[119px] rounded-full"><IngredientStartSearch/></div>
                    </div>
                    <div>Molimo vas pretražite bazu sastojaka</div>
                </div>
            }
            {
                ingredients && ingredients.length > 0 &&
                <div className="grow overflow-y-scroll scroll-content mb-[50px] pr-[10px]">
                    <table className="w-full border-separate border-spacing-y-[12px] overflow-hidden pb-12">
                        <thead>
                        <tr className="text-xs text-light sticky top-0 bg-white h-[32px]">
                            <td className="pl-4">Naziv</td>
                            <td className="min-w-[60px]">Energija</td>
                            <td className="min-w-[60px]">Masti</td>
                            <td className="min-w-[60px]">U.Hidrati</td>
                            <td className="min-w-[60px]">Proteini</td>
                            <td className="min-w-[40px]">So</td>
                            <td className="w-[100px]">Baza</td>
                            <td className="w-[100px]"></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            ingredients.map(ingredient => (
                                <Ingredient key={ingredient.id}
                                            ingredient={ingredient}
                                            isValidTotal={isValidTotal}
                                            onAdd={handleAddBtn}
                                            onDelete={handleDeleteManuItemBtn}
                                            onChange={handleIngredientChange}/>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            }

            <div
                className="absolute left-0 right-0 bottom-0 min-h-[62px] p-4 bg-white flex space-between items-center px-6">
                <div className="flex  text-xs  text-light flex-wrap">
                    {
                        recipe?.ingredients?.length === 0 &&
                        <div>
                            <span className="text-primary">Izabrani sastojci:</span>
                            <span className="text-light ml-2">Trenutno nema izabranih sastojaka</span>
                        </div>
                    }
                    {

                        recipe?.ingredients.map((ingredient, index) => (
                            <div key={ingredient.id} className="group h-[22px]  flex items-center bg-section-header rounded-[32px] pl-[12px]
                                            pr-[4px] mr-4
                                            hover:cursor-pointer border
                                            hover:border-error
                                            hover:text-error" onClick={() => handleRemoveTagClick(ingredient)}>
                                <span
                                    className="overflow-hidden max-w-[100px] text-ellipsis whitespace-nowrap">{ingredient?.ingredient.name}</span>
                                <XCircleIcon className="group-hover:fill-error" width={14}/>
                            </div>
                        ))
                    }
                </div>
                <div><Button onClick={onClose}>U redu</Button></div>
            </div>
        </div>
    )
}

IngredientSearch.propTypes = {
    db: PropTypes.oneOf(["eurofir", "nutrical"])
}

IngredientSearch.defaultProps = {
    db: "eurofir"
}

export default IngredientSearch;