import React from "react";
import TextField from "./TextField";
import Button from "./Button";
import {TrashIcon} from "../icons";

const ComparativeStatements = ({statements, onChange, handleNewComparativeStatement}) => {


    const handleChange = (id, value) => {
        onChange(statements.map(statement => {
            if (statement.id === id) {
                statement.statement = value
            }

            return statement
        }))
    }

    const handleDeleteBtnClick = (id) => {
        onChange(statements.filter(statement => id !== statement.id))
    }

    return (
        <div className="p-4">
            {
                statements && statements.sort((a, b) => {
                    if (a.id === null) return 1;
                    return a.id - b.id
                }).map((statement) => (
                    <div className="mb-4 relative" key={statement.id}>
                        <TextField value={statement.statement} onChange={(e) => handleChange(statement.id, e.target.value)}
                                   placeholder="Unesite tekst izjave"/>
                        <TrashIcon className="absolute right-[5px] top-[10px] hover:cursor-pointer"
                                   onClick={() => handleDeleteBtnClick(statement.id)}/>
                    </div>
                ))
            }

            <div className="mt-4">
                <Button size="large" color="secondary"
                        onClick={handleNewComparativeStatement}>+ Dodaj novu izjavu</Button>
            </div>
        </div>
    )
}

export default ComparativeStatements