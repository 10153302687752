import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import {apiClient} from "../index";

const PrivateRoute = ({children}) => {

    const { initialized, keycloak } = useKeycloak();

    if (!initialized || !keycloak) {
        return
    }

    if (!keycloak.authenticated) {
        keycloak.login()
        return
    }

    axios.interceptors.request.use((config) => {
        if (keycloak.token) {
            config.headers.Authorization = `Bearer ${keycloak.token}`;
        }
        return config;
    });

    return children
}

export default PrivateRoute