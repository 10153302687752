import React from "react"
import "../css/menuItem.css"

const MenuItem = (props) => {

    const {startIcon, endIcon, onClick} = props

    return (
        <div className="menu-item" onClick={onClick}>
            {startIcon && <div className="btn-start-icon">{startIcon}</div>}
            {props.children}
            {endIcon && <div className="btn-end-icon">{endIcon}</div>}
        </div>
    )
}

export default MenuItem