import axios from "axios";
import {host} from "../config/api";

export const downloadFile = (id, fileName) => {

    axios({
        url: `${host}/v1/media/${id}`,
        method: "get",
        responseType: 'blob'
    }).then((response) => {

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', `${fileName}`);

        document.body.appendChild(link);

        link.click();

        link.remove();
    })
}