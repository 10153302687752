
export const selectStyle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: '#EFEFEF',
        borderRadius: '8px',
        border: 'none',
        placeholderColor: 'blue',

    }),
    option: (baseStyles, state) => ({
        fontFamily: 'Poppins',
        fontSize: '12px',
        padding: '5px 20px',
        ...(state.isFocused && {
            backgroundColor: '#ebebeb',
            cursor: 'pointer'
        })
    }),
    groupHeading: (baseStyles, state) => ({
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '12px',
        paddingLeft: '10px'
    }),
    input: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0px 10px 0px 0px'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    })
}