import React from "react"
import classNames from "classnames";
import Checkbox from "./Checkbox";


const Colors = {
    AMBER: "#EB9C02",
    GREEN: "#67BE01",
    RED: "#E81111",
    WHITE: "#FFFFFF"
}

const Nutrient = ({title, color, children, percentage, className}) => {

    const styles = {
        backgroundColor: Colors[color]
    }

    const classes = classNames(className,
        "w-[90px] h-[110px] bg-red flex flex-col items-center space-between border-[3px] rounded-[30px] border-traffic-light p-[4px]")

    return (
        <div className={classes} style={styles}>
            <div className={`text-xs font-medium mt-1 text-opacity-80`} style={{color: color === "WHITE" ? '#818181' : 'rgb(255 255 255 / 0.8)'}}>{title}</div>
            <div className="grow">{children}</div>
            <div className={`w-full h-[35px] rounded-b-[26px] rounded-t-[4px] bg-white bg-opacity-80 
            flex items-baseline justify-center border 
            ${color=== 'WHITE' ? 'border-traffic-light' : 'border-transparent'}`}>
                <div className=" font-medium text-[24px] flex items-center">{percentage}</div>
                <div className="font-medium text-[12px] ml-[1px] relative bottom-[3px]">%</div>
            </div>
        </div>
    )
}

const TrafficLight = ({value, portionAmount, unit, energy, reportFlag, onReportFlagChange}) => {

    if (!value) {
        return <></>
    }

    const unitLabel = unit ? unit.toLowerCase() : 'g'

    return (
        <div>
            <div className="flex p-4 items-center">
                <div className="flex items-center hover:cursor-pointer hover:underline"  onClick={onReportFlagChange}>
                    <Checkbox checked={reportFlag}/>
                    <div className="ml-2 mr-8 font-medium text-[14px]">Prikaži u izveštaju</div>
                </div>

                <div className="flex flex-col justify-center align-center p-4 bg-section-header rounded-[8px] grow">
                    <div className="mb-4 font-medium text-[14px]">
                        {portionAmount && `Porcija proizvoda (${portionAmount}${unitLabel}) sadrži:`}
                        {!portionAmount && `100${unitLabel} proizvoda sadrži:`}
                    </div>
                    <div className="flex">
                        <Nutrient title="Energija"
                                  color={value?.energy?.color}
                                  percentage={value?.energy?.percentage}>
                            <div className="flex flex-col justify-center items-center font-medium text-[14px] leading-tight h-full">
                                <div> {value?.energy?.nutrient_value.split(",")[0]} kJ</div>
                                <div> {value?.energy?.nutrient_value.split(",")[1]} kcal</div>
                            </div>
                        </Nutrient>
                        <Nutrient title="Masti" color={value?.fat?.color}
                                  percentage={value?.fat?.percentage}
                                  className="relative left-[-3px] font-medium">
                            <span className="text-[24px] text-white">{value?.fat?.nutrient_value}</span>
                            <span className="ml-[2px] text-white">g</span>
                        </Nutrient>
                        <Nutrient title="Zasićene" color={value?.fat_saturated?.color}
                                  percentage={value?.fat_saturated?.percentage}
                                  className="relative left-[-6px] font-medium">
                            <span className="text-[24px] text-white ">{value?.fat_saturated?.nutrient_value}</span>
                            <span className="ml-[2px] text-white">g</span>
                        </Nutrient>
                        <Nutrient title="Šećeri" color={value?.sugar?.color}
                                  percentage={value?.sugar?.percentage}
                                  className="relative left-[-9px] font-medium">
                            <span className="text-[24px] text-white ">{value?.sugar?.nutrient_value}</span>
                            <span className="ml-[2px] text-white">g</span>
                        </Nutrient>
                        <Nutrient title="So" color={value?.salt?.color}
                                  percentage={value?.salt?.percentage}
                                  className="relative left-[-12px] font-medium">
                            <span className="text-[24px] text-white ">{value?.salt?.nutrient_value}</span>
                            <span className="ml-[2px] text-white">g</span>
                        </Nutrient>
                    </div>
                    <div className="font-medium text-[14px] mt-4">od preporučenog referentnog unosa za odraslu osobu</div>

                    {
                        portionAmount &&
                        <div className=" text-[14px] mt-4">
                            <span>Prosečna energetska vrednost 100g proizvoda: </span>
                            <span className="font-medium">
                            <span>Energija</span>
                            <span> {energy?.nutrient_value.split(",")[0]} kJ</span>
                            <span> / </span>
                            <span> {energy?.nutrient_value.split(",")[1]} kcal</span>
                        </span>
                        </div>
                    }
                </div>
            </div>
            {/*<div className="flex">*/}
            {/*    <div>Prikazi u izvestaju</div>*/}
            {/*</div>*/}
        </div>
    )
}

export default TrafficLight