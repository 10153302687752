import React from "react"
import PropTypes from "prop-types";

import "../css/navigation.css"
import {useKeycloak} from "@react-keycloak/web";
import Menu from "./Menu";
import MenuItem from "./MenuItem";

const Navigation = (props) => {

    const {page} = props
    const {keycloak } = useKeycloak();

    const firstName = keycloak?.tokenParsed?.given_name;
    const userPicture = keycloak?.tokenParsed?.picture;

    const profileEle = (
        <div className="profile-pic">
            {userPicture && <img src={userPicture} alt=" "/>}
            {!userPicture && <span>{firstName.charAt(0)}</span>}
        </div>
    )
    return (
        <div className="navigation-wrapper">
            <div className="navigation">
                <div>
                    <div className="logo">
                        <img src="/logo.svg"/>
                    </div>
                    <div className="items">
                        <div className="item">
                            <a className={page === "requests" ? "selected" : ""} href="/requests">
                                {page === "requests" && <img src="/requests.svg" alt=" "/>}
                                <span>Zahtevi</span>
                            </a>
                        </div>
                        <div className="item">
                            <a className={page === "recipes" ? "selected" : ""}>
                                {page === "recipes" && <img src="/recipes.svg" alt=" "/>}
                                <span>Recepture</span>
                            </a>
                        </div>
                        <div className="item"><a className={page === "about" ? "selected" : ""}>O nama</a></div>
                        <div className="item"><a className={page === "db" ? "selected" : ""}>Baza podataka</a></div>
                    </div>
                    <div className="user">
                        <span className="mr-1 text-[12px] font-semibold">Zdravo,</span><span className="first-letter:uppercase text-[12px] font-semibold"> {firstName}</span>

                        <Menu element={profileEle} position="right">
                            <MenuItem className="font-semibold"  startIcon={<img width={12} src="/logout.svg"/>} onClick={() => keycloak.logout()}>Logout</MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>

    )
}

Navigation.propTypes = {
    page: PropTypes.oneOf(["requests", "recipes", "about", "db"]).isRequired
}

export default Navigation