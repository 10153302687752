import React, {useState} from "react"

import "../css/nutritionTable.css"
import Checkbox from "./Checkbox";
import classNames from "classnames";
import ThCheckbox from "./ThCheckbox";
import {Unit} from "../containers/RecipeContainer";


const NType = {
    STANDARD: "STANDARD",
    STANDARD_RDI: "STANDARD_RDI",
    PORTION: "PORTION",
    PORTION_RDI: "PORTION_RDI"
}

const EmptyTable = () => {
    return (
        <div className="recipe-empty-section">
            <div>
                <img src="/iconPizzaBold.svg"/>
                <div>Nutritivna tabela ce se prikazati nakon unosa bar jednog sastojka</div>
            </div>
        </div>
    )
}

const Loader = () => {
    return (
        <div className="recipe-empty-section">
            <div>
                <div>Loading...</div>
            </div>
        </div>
    )
}

const Tr = ({children, className, selectable, ...props}) => {

    const trClasses = classNames(className, {
        "h-48px border-b border-table text-sm text-primary": true,
        "hover:cursor-pointer hover:bg-gray-50": selectable
    })

    return (
        <tr className={trClasses} {...props}>
            {children}
        </tr>
    )
}

const Td = ({children, className}) => {

    const tdClasses = classNames(className, "px-[10px]")

    return (
        <td className={tdClasses}>
            {children}
        </td>
    )
}

export const NValue = ({value, unit, display=true}) => {
    return (
        <div className=" h-[48px] flex items-center justify-center">
            {
                display &&
                <div className="flex items-center">
                    <span className="text-sm text-primary font-medium w-[70px] text-align-right">{value}</span>
                    <span className="text-sm text-light ml-1 w-[30px] text-align-left pl-2 basis-[60px]">{unit}</span>
                </div>
            }

            {
                !display && <span className="text-light text-center w-full">--</span>
            }
        </div>
    )
}

const NCheckbox = ({label, flagValue, isSubsection, disabled = false, isDeclaration = false}) => {

    return (
        <div className="flex items-center">
            {!isDeclaration && <Checkbox checked={flagValue} disabled={disabled}/>}
            <span className={isSubsection ? "px-6" : "px-4"}>{label}</span>
        </div>
    )
}

const getNutrientFlagSelected = (selected) => {

    return {
        "mono_unsaturated": selected,
        "poly_unsaturated": selected,
        "polyol": selected,
        "starch": selected,
        "fibre": selected
    }
}

const NutritionTable = ({
                            nutricalValues, isLoading, isValid,
                            nutrientFlag, setNutrientFlag, onNutrientFlagChange, portionAmount,
                            isDeclaration = false,
                            unit
                        }) => {

    const [selectAllChecked, setSelectAllChecked] = useState(false)

    if (isLoading) {
        return <Loader/>
    }

    if (!nutricalValues || !isValid()) {
        return <EmptyTable/>
    }

    let nutritionStandard = nutricalValues.nutrient_standard
    let nutritionPortion = nutricalValues.nutrient_portion

    let isValidStandard = nutritionStandard && nutritionStandard.nutrient_total > 95 && nutritionStandard.nutrient_total < 105
    let isValidPortion = nutritionPortion && nutritionPortion.nutrient_total > 95 && nutritionPortion.nutrient_total < 105


    const getStandard = (attrName) => nutricalValues?.nutrient_standard?.nutrient_basic[attrName]
    const getStandardRdi = (attrName) => nutricalValues?.nutrient_standard?.nutrient_basic_rdi[attrName]

    const getStandardExtended = (attrName) => nutricalValues?.nutrient_standard?.nutrient_extended[attrName]
    const getStandardExtendedRdi = (attrName) => nutricalValues?.nutrient_standard?.nutrient_extended_rdi[attrName]

    const getPortion = (attrName) => nutricalValues?.nutrient_portion?.nutrient_basic[attrName]
    const getPortionRdi = (attrName) => nutricalValues?.nutrient_portion?.nutrient_basic_rdi[attrName]

    const getPortionExtended = (attrName) => nutricalValues?.nutrient_portion?.nutrient_extended[attrName]
    const getPortionExtendedRdi = (attrName) => nutricalValues?.nutrient_portion?.nutrient_extended_rdi[attrName]

    const handleSelectAllClick = (e) => {
        const nutrientFlagChecked = getNutrientFlagSelected(!selectAllChecked)
        setNutrientFlag({
            ...nutrientFlag,
            ...nutrientFlagChecked
        })
        setSelectAllChecked((prevState) => !prevState)
    }

    if (!nutrientFlag) {
        return
    }

    return (
        <div className="nutrition-table">
            <table className="w-full border-collapse">
                <thead>
                {
                    isDeclaration &&
                    <Tr className="h-[48px] bg-secondary-action text-white text-align-center">
                        <td></td>
                        <td>
                            <div>na</div>
                            <div className="font-medium">
                                <span>100</span>
                                <span className="lowercase">{unit}</span>
                            </div>
                        </td>
                        <td>RU%</td>
                        <td>
                            <div>Porcija</div>
                            <div className="font-medium">
                                {
                                    portionAmount &&
                                    <>
                                        <span>{portionAmount}</span>
                                        <span className="lowercase">{unit}</span>
                                    </>
                                }
                            </div>
                        </td>
                        <td>
                            RU%
                        </td>
                    </Tr>
                }

                {!isDeclaration &&
                    <Tr className="h-[48px]">
                        <Td>
                            <div className="flex items-center hover:cursor-pointer hover:underline"
                                 onClick={handleSelectAllClick}>
                                <Checkbox checked={selectAllChecked}/>
                                <span className="px-4">Označi sve</span>
                            </div>
                        </Td>
                        <ThCheckbox description="na" label={unit === Unit.ML ? '100ml' : '100g'}
                                    checked={true} disabled="true"
                                    onChange={() => {}}/>
                        <ThCheckbox description="" label="RU%"
                                    checked={nutrientFlag.referent_entry_percentage}
                                    onChange={() => onNutrientFlagChange("referent_entry_percentage")}/>
                        <ThCheckbox description="Porcija" label={portionAmount && unit ? portionAmount + unit.toLowerCase() : ''}
                                    checked={nutrientFlag.portion}
                                    onChange={() => onNutrientFlagChange("portion")}/>
                        <ThCheckbox description="" label="RU%"
                                    checked={nutrientFlag.referent_entry_percentage && nutrientFlag.portion}
                                    disabled={true}/>
                    </Tr>
                }
                </thead>
                <tbody>


                <Tr className="bg-section-header">
                    <Td>
                        <NCheckbox label="Energija" flagValue={true} disabled={true} isDeclaration={isDeclaration}/>
                    </Td>
                    <Td>
                        <div>
                            <NValue unit="kJ" value={getStandard("energy_kj")}/>
                            <NValue unit="kcal" value={getStandard("energy_kcal")}/>
                        </div>
                    </Td>
                    <Td>
                        <div>
                            <NValue display={nutrientFlag.referent_entry_percentage} unit="%" value={getStandardRdi("energy_kj")}/>
                        </div>

                    </Td>
                    <Td>
                        <NValue display={nutrientFlag.portion} unit="kJ" value={getPortion("energy_kj")}/>
                        <NValue display={nutrientFlag.portion} unit="kcal" value={getPortion("energy_kcal")}/>
                    </Td>
                    <Td>
                        <NValue display={nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionRdi("energy_kj")}/>
                    </Td>
                </Tr>
                <Tr  className="border-dashed">
                    <Td><NCheckbox label="Masti" flagValue={true} disabled={true} isDeclaration={isDeclaration}/></Td>
                    <Td><NValue unit="g" value={getStandard("fat")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage} unit="%" value={getStandardRdi("fat")}/></Td>
                    <Td><NValue display={nutrientFlag.portion} unit="g" value={getPortion("fat")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionRdi("fat")}/></Td>
                </Tr>
                <Tr className="border-dashed">
                    <Td><NCheckbox label="Od kojih zasićene masne kiseline"
                                   flagValue={true} disabled={true} isSubsection={true} isDeclaration={isDeclaration}/></Td>
                    <Td><NValue unit="g" value={getStandard("fat_saturated")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage} unit="%" value={getStandardRdi("fat_saturated")}/></Td>
                    <Td><NValue display={nutrientFlag.portion} unit="g" value={getPortion("fat_saturated")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionRdi("fat_saturated")}/></Td>
                </Tr>
                { (nutrientFlag.mono_unsaturated || !isDeclaration) &&
                    <Tr  className="border-dashed" selectable={true} onClick={() => onNutrientFlagChange('mono_unsaturated')}>
                        <Td><NCheckbox label="Od kojih mononezasićene masne kiseline"
                                       flagValue={nutrientFlag.mono_unsaturated} isSubsection={true}
                                       isDeclaration={isDeclaration}/></Td>
                        <Td><NValue display={nutrientFlag.mono_unsaturated} unit="g" value={getStandardExtended("mono_unsaturated")}/></Td>
                        <Td><NValue display={nutrientFlag.mono_unsaturated && nutrientFlag.referent_entry_percentage}
                                    unit="%" value={getStandardExtendedRdi("mono_unsaturated")}/></Td>
                        <Td><NValue display={nutrientFlag.mono_unsaturated && nutrientFlag.portion} unit="g" value={getPortionExtended("mono_unsaturated")}/></Td>
                        <Td><NValue display={nutrientFlag.mono_unsaturated && nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionExtendedRdi("mono_unsaturated")}/></Td>
                    </Tr>
                }
                {(nutrientFlag.poly_unsaturated || !isDeclaration) &&
                    <Tr className="" selectable={true} onClick={() => onNutrientFlagChange('poly_unsaturated')}>
                        <Td><NCheckbox label="Od kojih polinezasićene masne kiseline"
                                       flagValue={nutrientFlag.poly_unsaturated} isSubsection={true}
                                       isDeclaration={isDeclaration}/></Td>
                        <Td><NValue display={nutrientFlag.poly_unsaturated} unit="g" value={getStandardExtended("poly_unsaturated")}/></Td>
                        <Td><NValue display={nutrientFlag.poly_unsaturated && nutrientFlag.referent_entry_percentage} unit="%" value={getStandardExtendedRdi("poly_unsaturated")}/></Td>
                        <Td><NValue display={nutrientFlag.poly_unsaturated && nutrientFlag.portion} unit="g" value={getPortionExtended("poly_unsaturated")}/></Td>
                        <Td><NValue display={nutrientFlag.poly_unsaturated && nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionExtendedRdi("poly_unsaturated")}/></Td>
                    </Tr>
                }
                <Tr  className="border-dashed bg-section-header">
                    <Td><NCheckbox label="Ugljeni hidrati" flagValue={true} disabled={true}
                                   isDeclaration={isDeclaration}/></Td>
                    <Td><NValue unit="g" value={getStandard("carbohydrate")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage} unit="%" value={getStandardRdi("carbohydrate")}/></Td>
                    <Td><NValue display={nutrientFlag.portion} unit="g" value={getPortion("carbohydrate")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionRdi("carbohydrate")}/></Td>
                </Tr>
                <Tr  className="border-dashed bg-section-header">
                    <Td><NCheckbox label="Od kojih šećeri" flagValue={true} disabled={true} isSubsection={true}
                                   isDeclaration={isDeclaration}/></Td>
                    <Td><NValue unit="g" value={getStandard("sugar")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage} unit="%" value={getStandardRdi("sugar")}/></Td>
                    <Td><NValue display={nutrientFlag.portion} unit="g" value={getPortion("sugar")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionRdi("sugar")}/></Td>
                </Tr>
                { (nutrientFlag.polyol || !isDeclaration) &&
                    <Tr  className="border-dashed bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('polyol')}>
                        <Td><NCheckbox label="Od kojih polioli" flagValue={nutrientFlag.polyol} isSubsection={true}
                                       isDeclaration={isDeclaration}/></Td>
                        <Td><NValue display={nutrientFlag.polyol} unit="g" value={getStandardExtended("polyol")}/></Td>
                        <Td><NValue display={nutrientFlag.polyol && nutrientFlag.referent_entry_percentage} unit="%" value={getStandardExtendedRdi("polyol")}/></Td>
                        <Td><NValue display={nutrientFlag.polyol && nutrientFlag.portion} unit="g" value={getPortionExtended("polyol")}/></Td>
                        <Td><NValue display={nutrientFlag.polyol && nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionExtendedRdi("polyol")}/></Td>
                    </Tr>
                }
                {  (nutrientFlag.starch || !isDeclaration) &&
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('starch')}>
                        <Td><NCheckbox label="Od kojih skrob" flagValue={nutrientFlag.starch} isSubsection={true}
                                       isDeclaration={isDeclaration}/></Td>
                        <Td><NValue display={nutrientFlag.starch} unit="g" value={getStandardExtended("starch")}/></Td>
                        <Td><NValue display={nutrientFlag.starch && nutrientFlag.referent_entry_percentage} unit="%" value={getStandardExtendedRdi("starch")}/></Td>
                        <Td><NValue display={nutrientFlag.starch && nutrientFlag.portion} unit="g" value={getPortionExtended("starch")}/></Td>
                        <Td><NValue display={nutrientFlag.starch && nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionExtendedRdi("starch")}/></Td>
                    </Tr>
                }
                { (nutrientFlag.fibre || !isDeclaration) &&
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('fibre')}>
                        <Td><NCheckbox label="Vlakna" flagValue={nutrientFlag.fibre} isDeclaration={isDeclaration}/></Td>
                        <Td><NValue display={nutrientFlag.fibre} unit="g" value={getStandardExtended("fibre")}/></Td>
                        <Td><NValue display={nutrientFlag.fibre && nutrientFlag.referent_entry_percentage} unit="%" value={getStandardExtendedRdi("fibre")}/></Td>
                        <Td><NValue display={nutrientFlag.fibre && nutrientFlag.portion} unit="g" value={getPortionExtended("fibre")}/></Td>
                        <Td><NValue display={nutrientFlag.fibre && nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionExtendedRdi("fibre")}/></Td>
                    </Tr>
                }
                <Tr className="bg-section-header">
                    <Td><NCheckbox label="Proteini" flagValue={true} disabled={true}
                                   isDeclaration={isDeclaration}/></Td>
                    <Td><NValue unit="g" value={getStandard("protein")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage} unit="%" value={getStandardRdi("protein")}/></Td>
                    <Td><NValue display={nutrientFlag.portion} unit="g" value={getPortion("protein")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionRdi("protein")}/></Td>
                </Tr>
                <Tr>
                    <Td><NCheckbox label="So" flagValue={true} disabled={true} isDeclaration={isDeclaration}/></Td>
                    <Td><NValue unit="g" value={getStandard("salt")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage} unit="%" value={getStandardRdi("salt")}/></Td>
                    <Td><NValue display={nutrientFlag.portion} unit="g" value={getPortion("salt")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionRdi("salt")}/></Td>
                </Tr>
                <Tr className="bg-section-header">
                    <Td><NCheckbox label="Voda" flagValue={false} disabled={true} isDeclaration={isDeclaration}/></Td>
                    <Td><NValue unit="g" value={getStandard("water")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage} unit="%" value={getStandardRdi("water")}/></Td>
                    <Td><NValue display={nutrientFlag.portion} unit="g" value={getPortion("water")}/></Td>
                    <Td><NValue display={nutrientFlag.referent_entry_percentage && nutrientFlag.portion} unit="%" value={getPortionRdi("water")}/></Td>
                </Tr>
                </tbody>
            </table>

            {
                !isDeclaration && nutritionStandard &&
                <div className={`flex justify-between m-[15px] align-center rounded-xl px-3 h-[36px] bg-table-info`}>
                    <div className="flex align-end">
                        <div className="ml-3 text-sm text-primary">Ukupne vrednosti na 100<span>{unit?.toLowerCase()}</span></div>
                    </div>
                    <div className="flex align-end">
                        <div
                            className={`ml-3 text-base font-semibold ${!isValidStandard && "text-red-600"} ${isValidStandard && "text-green-600"}`}>{nutritionStandard.nutrient_total}%
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default NutritionTable