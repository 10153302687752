import axios from "axios";
import {host} from "../config/api";


export const getRetentionFactorsById = (langualCode) => {

    return axios({
        method: 'get',
        url: `${host}/v1/retention-factors`,
        params: {
            "langual-code": langualCode
        }
    }).then(response => {
        return convert(response.data)
    })
}

export const retentionFactorToOption = (retentionFactor) => {

    if (!retentionFactor) {
        return null
    }

    return {
        label: retentionFactor.cooking_method_langual_description,
        value: retentionFactor.id,
        ...retentionFactor,
    }
}

const convert = (data) => {

    if (!data || !data.method_preparations) {
        return []
    }

    return data.method_preparations.map(methodPreparation => ({
        label: methodPreparation.method_preparation,
        options: methodPreparation?.retention_factors?.map(retentionFactorToOption)
    }))
}