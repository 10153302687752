import React, {useEffect, useState} from "react";
import Modal from "./Modal";
import Select from "react-select";
import {getRetentionFactorsById, retentionFactorToOption} from "../service/RetentionFactorService";
import Button from "./Button";
import {selectStyle} from "../service/Select";


const RetentionFactorPicker = ({onClose, langualCode, onChange, defaultRetentionFactors}) => {

    const [selectedOption, setSelectedOption] = useState(null)
    const [data, setData] = useState(null)

    const [retentionFactors, setRetentionFactors] = useState(defaultRetentionFactors || [])

    useEffect(() => {
        getRetentionFactorsById(langualCode).then(retentionFactors => {
            setData(retentionFactors)
        })

    }, [langualCode])

    const firstRetentionFactor = defaultRetentionFactors && defaultRetentionFactors.length > 0 ?
        retentionFactorToOption(defaultRetentionFactors[0].retention_factor) : null

    const secondRetentionFactor = defaultRetentionFactors && defaultRetentionFactors.length > 1 ?
        retentionFactorToOption(defaultRetentionFactors[1].retention_factor) : null

    const thirdRetentionFactor = defaultRetentionFactors && defaultRetentionFactors.length > 2 ?
        retentionFactorToOption(defaultRetentionFactors[2].retention_factor) : null



    const handleChange = ((retentionFactor, index) => {
        if (index >= retentionFactors.length) {
            setRetentionFactors([
                ...retentionFactors,
                {
                    id: null,
                    retention_factor: retentionFactor
                }
            ])

            return
        }

        let result = [...retentionFactors]
        result[index].retention_factor = retentionFactor

        setRetentionFactors(result)
    })

    const handleSubmit = () => {
        onChange(retentionFactors.filter(e => e?.retention_factor != null))
    }

    const formatOptionLabel = ({label, cooking_method_langual_code, additional_food_classification}) => {
        return (
            <div className="flex space-between items-center">
                <div>
                    <span>{label}</span>
                    {additional_food_classification && <span className="ml-2 text-light">({additional_food_classification})</span>}
                </div>
                <div>{cooking_method_langual_code}</div>
            </div>
        )
    }

    return (
        <Modal show={true} onClose={onClose} contentClassName="bg-white font-normal basis-[550px] overflow-hidden">
            <Modal.Header className="">
                <div>
                    <div className="text-[20px] font-normal mb-[20px]">Izaberi langual faktor</div>

                    <div className="text-[14px] font-normal ">Izaberite metode termičke obrade ili upišite langual
                        faktor
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body className="relative w-full p-6">
                <div className="">
                    <div className="mb-1">Prva termička obrada</div>
                    <Select
                        styles={selectStyle}
                        placeholder="Izaberite langual faktor"
                        defaultValue={firstRetentionFactor}
                        onChange={(retentionFactor) => handleChange(retentionFactor, 0)}
                        options={data}
                        formatOptionLabel={formatOptionLabel}
                        isClearable={true}
                    />

                    <div className="mb-1 mt-6">Druga termička obrada</div>
                    <Select
                        styles={selectStyle}
                        isDisabled={retentionFactors.length < 1 }
                        placeholder="Izaberite langual faktor"
                        defaultValue={secondRetentionFactor}
                        onChange={(retentionFactor) => handleChange(retentionFactor, 1)}
                        options={data}
                        formatOptionLabel={formatOptionLabel}
                        isClearable={true}
                    />

                    <div className="mb-1 mt-6">Treća termička obrada</div>
                    <Select
                        styles={selectStyle}
                        isDisabled={retentionFactors.length < 2 }
                        placeholder="Izaberite langual faktor"
                        defaultValue={thirdRetentionFactor}
                        onChange={(retentionFactor) => handleChange(retentionFactor, 2)}
                        options={data}
                        formatOptionLabel={formatOptionLabel}
                        isClearable={true}
                    />

                    <div className="mb-[50px]"></div>
                </div>
            </Modal.Body>

            <Modal.Footer isValid={true}>
                <div className="w-full grow flex items-center justify-center h-[70px]"
                     onClick={handleSubmit}><Button
                    size="large">Primeni</Button></div>
            </Modal.Footer>
        </Modal>
    )
}

export default RetentionFactorPicker