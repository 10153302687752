import React from "react";
import Checkbox from "./Checkbox";
import "../css/ingredientDeclartionTable.css"
import ContentEditable from "./ContentEditable";


const DELAY = 2000

const IngredientDeclarationTable = (props) => {

    const {report, handleReportChange} = props

    if (!report) {
        return
    }

    const sortIngredients = (recipe) => {
        recipe.ingredients = recipe.ingredients.sort((a, b) => -(a.percentage - b.percentage))
    }

    let recipeLocal = Object.assign({}, report)

    sortIngredients(recipeLocal)

    const onCorrectedNameChanged = (index, value) => {
        recipeLocal.ingredients[index].corrected_name = value

        handleReportChange({
            report: recipeLocal,
            push: true,
            fetch: false,
            delay: DELAY
        })
    }

    const onPctFlagChange = (index) => {
        recipeLocal.ingredients[index].percentage_flag = !recipeLocal.ingredients[index].percentage_flag

        handleReportChange({
            report: recipeLocal,
            push: true,
            fetch: false,
            delay: DELAY
        })
    }



    return (
        <div className="ingredient-table ingredient-declaration-table">
            <table>
                <thead>
                    <tr className="text-xs text-light">
                        <th className="w-40-pct-sm">Naziv sastojka</th>
                        <th className="w-40-pct-sm">Koriguj naziv</th>
                        <th className="w-10-pct-sm text-align-center">% od proizvoda</th>
                        <th className="w-10-pct-sm text-align-right whitespace-nowrap">Prikaži %</th>
                    </tr>
                </thead>
                <tbody className="text-sm text-primary font-medium">
                {
                    recipeLocal.ingredients && recipeLocal.ingredients.map((ingredient, index) => (
                        <tr key={ingredient.id + ingredient.ingredient_type}>
                            <td>
                                <div className="ingredient-name">{ingredient.ingredient.name}</div>
                                {
                                    ingredient.ingredient.ingredients_text &&
                                    <div className="fs-small">
                                        <span>(</span>
                                        <span dangerouslySetInnerHTML={{__html: ingredient.ingredient.ingredients_text}}></span>
                                        <span>)</span>
                                    </div>
                                }
                            </td>
                            <td className="relative font-normal">
                                <ContentEditable rootClasses="text-area-declaration"
                                                 value={ingredient.corrected_name}
                                                 onChange={(value) => onCorrectedNameChanged(index, value)}/>

                            </td>
                            <td className="text-align-center fs-small">{ingredient.percentage}</td>
                            <td className="text-align-right">
                                <div >
                                    <Checkbox onClick={() => onPctFlagChange(index)} checked={ingredient.percentage_flag || false} />
                                </div>
                            </td>
                        </tr>
                    ))
                }
                </tbody>

            </table>
        </div>
    )
}

export default IngredientDeclarationTable