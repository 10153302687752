//export const host = "https://api-test.nutrikator.com" // for customers
//export const host = "https://api.nutrikator.com" // for developers
export const host = "https://api.nutrikator.com"; // prod
export const nutrical_requests_url = `${host}/v1/nutrical-requests/simple`;
export const ingredients_url = `${host}/v1/ingredients`;
export const recipes_url = `${host}/v1/recipes`;
export const get_recipe_by_nutrical_request = `${host}/v1/recipes/nutrical-request`;
export const nutrical_ingredients_url = `${host}/v1/nutrical-ingredients`;
export const nutrical_values_url = `${host}/v1/nutrical-values`;
export const recipe_comments_url = `${host}/v1/recipe-comments`;
export const recipe_report_url = `${host}/v1/reports`;
export const recipe_report_download_url = `${host}/v1/reports/download/recipe`;
export const recipe_report_generate_url = `${host}/v1/reports/generate/recipe`;
export const recipe_report_by_id_url = `${host}/v1/reports/nutrical-request`;
export const density_url = `${host}/v1/density`;
export const MEDIA_API = "https://api.nutrikator.com/v1/media";
