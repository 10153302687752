import React, {useState} from "react"
import "../css/portionCalc.css"
import {hasIngredients, isValid} from "../service/RecipeService";
import {DECIMAL_NUMBER_REGEX} from "../config/config";

export const NUMBER_OF_DECIMALS = 2

const Direction = {
    LEFT_TO_RIGHT: "left-to-right",
    RIGHT_TO_LEFT: "right-to-left"
}

const EmptyTable = () => {
    return (
        <div className="recipe-empty-section bg-body">
            <div>
                <img src="/iconDessertBold.svg"/>
                <div>Kalkulator ce biti dostupan kada se unese masa svakog sastojka</div>
            </div>
        </div>
    )
}


const PortionCalc = props => {

    const {recipe, updateRecipe, refreshNutricalValues} = props
    const [direction, setDirection] = useState(Direction.LEFT_TO_RIGHT)

    if (!isValid(recipe) || !hasIngredients(recipe)) {
        return <EmptyTable/>
    }

    const onYieldFactorChange = (e) => {
        if (!e.target.validity.valid) {
            return
        }

        let recipeLocal = Object.assign({}, recipe)
        recipeLocal.yield_factor = e.target.value
        recipeLocal.prepared_amount = calculateNetAmount() * recipeLocal.yield_factor
        recipeLocal.portion_count = ""
        recipeLocal.portion_amount =  ""

        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: false,
            delay: 0
        })

        refreshNutricalValues(recipeLocal)
    }

    const onPreparedAmountChange = (e) => {
        if (!e.target.validity.valid) {
            return
        }

        let recipeLocal = Object.assign({}, recipe)
        recipeLocal.prepared_amount = e.target.value
        recipeLocal.yield_factor = recipeLocal.prepared_amount / calculateNetAmount()
        recipeLocal.portion_count = ""
        recipeLocal.portion_amount =  ""

        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: false,
            delay: 0
        })

        refreshNutricalValues(recipeLocal)
    }

    const onPortionCountChange = (e) => {
        if (!e.target.validity.valid) {
            return
        }

        let recipeLocal = Object.assign({}, recipe)
        recipeLocal.portion_count = e.target.value
        recipeLocal.portion_amount = ""

        if (Number(recipeLocal.portion_count)) {
            recipeLocal.portion_amount = recipeLocal.prepared_amount / recipeLocal.portion_count
        }


        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: false,
            delay: 0
        })

        refreshNutricalValues(recipeLocal)
    }

    const onPortionAmountChange = (e) => {
        if (!e.target.validity.valid) {
            return
        }

        let recipeLocal = Object.assign({}, recipe)
        recipeLocal.portion_amount = e.target.value
        recipeLocal.portion_count = ""

        if (Number(recipeLocal.portion_amount)) {
            recipeLocal.portion_count = recipeLocal.prepared_amount / recipeLocal.portion_amount
        }

        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: false,
            delay: 0
        })

        refreshNutricalValues(recipeLocal)
    }

    const parseNumber = (number) => {
        if (!number) {
            return ""
        }

        if (typeof number === "string" && number.endsWith(".")) {
            return number
        }


        return parseFloat(Number(number).toFixed(NUMBER_OF_DECIMALS))
    }

    const round = (number) => {
        if (!number) {
            return ""
        }

        return Math.round(number)
    }

    const getIngredients = () => {
        return recipe.ingredients || []
    }

    const sum = (array = []) => {
        let total = 0
        for (let num of array) {
            total += num
        }

        return total
    }

    const calculateNetAmount = () => {
        return sum(getIngredients().filter(Boolean).map(e => e.amount).map(Number))
    }

    const netAmount = calculateNetAmount()

    return (
        <div className="portion-calc">
            <div className="left-calc">
                <div className="mass net-amount">
                    <div className="label text-light">Masa <span>({recipe?.default_unit?.toLowerCase()})</span></div>
                    <div className="value flex-grow">{netAmount}</div>
                </div>
                <div className="multiply operator">
                    <div>x</div>
                </div>
                <div className="yield">
                    <div className="label text-light text-primary">Yield faktor</div>
                    <input className="portion-input" type="text" pattern={DECIMAL_NUMBER_REGEX}
                           value={parseNumber(recipe.yield_factor)} onChange={onYieldFactorChange}/>
                </div>
                <div className="equal operator">
                    <div>=</div>
                </div>
                <div className="mass prepared-amount">
                    <div className="label text-light">Masa pripremljenog jela <span>({recipe?.default_unit?.toLowerCase()})</span></div>
                    <input className="portion-input flex-grow" pattern={DECIMAL_NUMBER_REGEX} type="text"
                           value={parseNumber(recipe.prepared_amount)} onChange={onPreparedAmountChange}/>
                </div>
            </div>

            <div className="operator divide">
                <span>%</span>
            </div>

            <div className="right-calc">
                <div className="portion-number">
                    <div className="label text-light">Broj porcija</div>
                    <input className="portion-input" type="text" pattern={DECIMAL_NUMBER_REGEX}
                           value={parseNumber(recipe.portion_count)} onChange={onPortionCountChange}/>
                </div>
                <div className="equal operator">
                    <div>=</div>
                </div>
                <div className="mass portion-amount">
                    <div className="label text-light">Masa porcije<span>({recipe?.default_unit?.toLowerCase()})</span></div>
                    <input className="portion-input flex-grow" pattern={DECIMAL_NUMBER_REGEX} type="text"
                           value={parseNumber(recipe.portion_amount)} onChange={onPortionAmountChange}/>
                </div>
            </div>
        </div>
    )
}

export default PortionCalc