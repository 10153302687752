import React, {useState} from "react"
import {InfoIcon} from "../icons";
import Modal from "./Modal";
import Button from "./Button";

const IngredientInfoItem = ({name, value, unit}) => {
    return (
        <div className="flex space-between items-center mb-[2px]">
            <div>{name}</div>
            <div className="flex items-center">
                <div className="text-right">{value || 0}</div>
                <div className="text-light ml-[2px] w-[20px]">{unit}</div>
            </div>
        </div>
    )
}

const IngredientInfo = ({ingredient, onAdd}) => {

    const [showInfo, setShowInfo] = useState(false)

    const handleInfoIconClick = () => setShowInfo(true)

    return (
        <div className="inline-flex">
            <div className="ml-2 w-[20px] h-[20px] bg-input rounded-full relative
                        flex items-center justify-center hover:cursor-pointer hover:bg-opacity-80"
                 onClick={handleInfoIconClick}><InfoIcon/>
            </div>

            {
                showInfo && (
                    <Modal show={true}
                           contentClassName="basis-[1000px] min-w-[200px] min-h-[200px] max-h-[100vh] bg-dark text-white"
                           onClose={() => {
                               setShowInfo(false)
                           }}>

                        <Modal.Body>
                            <div className="text-base font-medium mb-4 px-2">{ingredient.name}</div>

                            <div className="flex opacity-80">
                                <div className="grow basis-[300px] border-r border-white border-opacity-20 pl-2 pr-4">
                                    <div className="text-light mb-2">Nutritivna tabela</div>
                                    <div className="flex space-between items-center">
                                        <div>Energija</div>
                                        <div className="flex items-center pr-2">
                                            <div>{ingredient?.nutrient_basic?.energy_kj || 0} <span className="text-light">KJ</span></div>
                                            <div className="mr-1">,</div>
                                            <div>{ingredient?.nutrient_basic?.energy_kcal || 0} <span className="text-light">kcal</span></div>
                                        </div>
                                    </div>
                                    <IngredientInfoItem name="Masti" unit="g"
                                                        value={ingredient?.nutrient_basic?.fat}/>
                                    <IngredientInfoItem name="Zasićene m.k." unit="g"
                                                        value={ingredient?.nutrient_basic?.fat_saturated}/>
                                    <IngredientInfoItem name="Mononezasićene m.k." unit="g"
                                                        value={ingredient?.nutrient_extended?.mono_unsaturated}/>
                                    <IngredientInfoItem name="Polinezasićene m.k." unit="g"
                                                        value={ingredient?.nutrient_extended?.poly_unsaturated}/>
                                    <IngredientInfoItem name="Linoleinska k. (ALA)" unit="mg"
                                                        value={ingredient?.nutrient_fatty_acid?.alpha_linolenic_acid}/>
                                    <IngredientInfoItem name="Eikozapentaenoinska k. (EPA)" unit="mg"
                                                        value={ingredient?.nutrient_fatty_acid?.eicopentaenoic_acid}/>
                                    <IngredientInfoItem name="Dokozapentaenoinska k. (DPA)" unit="mg"
                                                        value={ingredient?.nutrient_fatty_acid?.docosapentaenoic_acid}/>
                                    <IngredientInfoItem name="Ugljeni Hidrati" unit="g"
                                                        value={ingredient?.nutrient_basic?.carbohydrate}/>
                                    <IngredientInfoItem name="Šećeri" unit="g"
                                                        value={ingredient?.nutrient_basic?.sugar}/>
                                    <IngredientInfoItem name="Polioli" unit="g"
                                                        value={ingredient?.nutrient_extended?.polyol}/>
                                    <IngredientInfoItem name="Eritritol" unit="g"
                                                      value={ingredient?.nutrient_extended?.erythritol}/>
                                    <IngredientInfoItem name="Skrob" unit="g"
                                                        value={ingredient?.nutrient_extended?.starch}/>
                                    <IngredientInfoItem name="Vlakna" unit="g"
                                                        value={ingredient?.nutrient_extended?.fibre}/>
                                    <IngredientInfoItem name="Proteini" unit="g"
                                                        value={ingredient?.nutrient_basic?.protein}/>
                                    <IngredientInfoItem name="So" unit="g"
                                                        value={ingredient?.nutrient_basic?.salt}/>
                                  <IngredientInfoItem name="Alkohol" unit="g"
                                                      value={ingredient?.nutrient_extended?.alcohol}/>
                                    <IngredientInfoItem name="Pepeo" unit="g"
                                                      value={ingredient?.nutrient_extended?.ash}/>
                                    <IngredientInfoItem name="Voda" unit="g"
                                                        value={ingredient?.nutrient_basic?.water}/>
                                </div>
                                <div className="grow basis-[300px] border-r border-white border-opacity-20 px-6">
                                    <div className="text-light mb-2">Vitamini</div>
                                    <IngredientInfoItem name="Vitamin A" unit="µg" value={ingredient?.nutrient_vitamin?.vitamin_a}/>
                                    <IngredientInfoItem name="Vitamin D" unit="µg" value={ingredient?.nutrient_vitamin?.vitamin_d}/>
                                    <IngredientInfoItem name="Vitamin E" unit="mg" value={ingredient?.nutrient_vitamin?.vitamin_e}/>
                                    <IngredientInfoItem name="Vitamin K" unit="µg" value={ingredient?.nutrient_vitamin?.vitamin_k}/>
                                    <IngredientInfoItem name="Vitamin C" unit="mg" value={ingredient?.nutrient_vitamin?.vitamin_c}/>
                                    <IngredientInfoItem name="Tiamin" unit="mg" value={ingredient?.nutrient_vitamin?.thiamin_b1}/>
                                    <IngredientInfoItem name="Riboflavin" unit="mg" value={ingredient?.nutrient_vitamin?.riboflavin_b2}/>
                                    <IngredientInfoItem name="Niacin" unit="mg" value={ingredient?.nutrient_vitamin?.niacin_b3}/>
                                    <IngredientInfoItem name="Vitamin B6" unit="mg" value={ingredient?.nutrient_vitamin?.vitamin_b6}/>
                                    <IngredientInfoItem name="Folna kiselina" unit="µg" value={ingredient?.nutrient_vitamin?.folate_b9}/>
                                    <IngredientInfoItem name="Vitamin B12" unit="µg" value={ingredient?.nutrient_vitamin?.vitamin_b12}/>
                                    <IngredientInfoItem name="Biotin" unit="µg" value={ingredient?.nutrient_vitamin?.biotin_b7}/>
                                    <IngredientInfoItem name="Pantotenska kiselina" unit="mg" value={ingredient?.nutrient_vitamin?.pantothenic_acid_b5}/>
                                </div>
                                <div className="grow basis-[300px] pl-6 pr-2">
                                    <div className="text-light mb-2">Minerali</div>
                                    <IngredientInfoItem name="Kalijum" unit="mg" value={ingredient?.nutrient_mineral?.potassium}/>
                                    <IngredientInfoItem name="Hloridi" unit="mg" value={ingredient?.nutrient_mineral?.chloride}/>
                                    <IngredientInfoItem name="Kalcijum" unit="mg" value={ingredient?.nutrient_mineral?.calcium}/>
                                    <IngredientInfoItem name="Fosfor" unit="mg" value={ingredient?.nutrient_mineral?.phosphorus}/>
                                    <IngredientInfoItem name="Magnezijum" unit="mg" value={ingredient?.nutrient_mineral?.magnesium}/>
                                    <IngredientInfoItem name="Gvožđe" unit="mg" value={ingredient?.nutrient_mineral?.iron}/>
                                    <IngredientInfoItem name="Cink" unit="mg" value={ingredient?.nutrient_mineral?.zinc}/>
                                    <IngredientInfoItem name="Bakar" unit="mg" value={ingredient?.nutrient_mineral?.copper}/>
                                    <IngredientInfoItem name="Mangan" unit="mg" value={ingredient?.nutrient_mineral?.manganese}/>
                                    <IngredientInfoItem name="Fluorid" unit="mg" value={ingredient?.nutrient_mineral?.fluoride}/>
                                    <IngredientInfoItem name="Fluorid" unit="mg" value={ingredient?.nutrient_mineral?.fluoride}/>
                                    <IngredientInfoItem name="Selen" unit="µg" value={ingredient?.nutrient_mineral?.selenium}/>
                                    <IngredientInfoItem name="Hrom" unit="µg" value={ingredient?.nutrient_mineral?.chromium}/>
                                    <IngredientInfoItem name="Molibden" unit="µg" value={ingredient?.nutrient_mineral?.molybdenum}/>
                                    <IngredientInfoItem name="Jod" unit="µg" value={ingredient?.nutrient_mineral?.iodine}/>
                                </div>
                            </div>
                            <div className="flex grow space-between mt-6 px-2">
                                <div className="flex space-between items-center w-full">
                                    <div className="flex items-center">
                                      <div className="text-white">{ingredient?.source || ''}</div>
                                      <div className="text-success ml-4">{ingredient?.nutrient_total || ''}</div>
                                    </div>
                                    <div className="ml-4 mr-8">
                                      <span>Langual code: </span>
                                      <span className="text-success">{ingredient?.langual_code || '--'}</span>
                                    </div>
                                </div>
                                {
                                    onAdd &&
                                    <Button onClick={onAdd}>
                                        + Dodaj
                                    </Button>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                )
            }
        </div>
    )
}

export default IngredientInfo