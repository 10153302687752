import React, {useState} from "react"
import classNames from "classnames";

const Expandable = ({children, title, className, ...props}) => {

    const [isExpanded, setExpanded] = useState(true)

    const toggle = () => {
        setExpanded(prevState => !prevState)
    }

    const rootClassNames = classNames(className, "rounded-[8px] bg-white overflow-hidden border-[2px] border-white")

    return (
        <div className={rootClassNames} {...props}>
            <div className="flex space-between items-center px-[15px]
                            hover:cursor-pointer h-[46px] bg-section-header
                            rounded-t-[8px]"
                 onClick={toggle}>
                <div className="font-medium text-primary text-sm">{title}</div>
                <div className={isExpanded ? '' : 'rotate-180'}><img src="/up.svg"/></div>
            </div>
            { isExpanded && children }
        </div>
    )
}

export default Expandable