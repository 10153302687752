import {useEffect, useState} from "react";
import axios from "axios";

import {recipe_report_url} from "../config/api";
import {getReport} from "../service/ReportService";

export const DocType = {
    REGULAR: "REGULAR",
    SIMPLE: "SIMPLE",
    DECLARATION: "DECLARATION"
}

const useRecipeReport = (requestId) => {

    const [report, setReport] = useState(null)
    const [isReportFetching, setIsReportFetching] = useState(false)
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
    const [error, setError] = useState(null)
    const [timeoutExec, setTimeoutExec] = useState(null)

    useEffect(() => {
        setIsReportFetching(true)
        getReport(requestId).then(response => {
            let report = response.data
            setReport(report)
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setIsReportFetching(false)
        })
    },[])

    const handleReportChange = ({report, push = true, fetch = false, delay = 0}) => {

        if (!report) {
            return
        }

        setReport(report)

        if (!push) {
            return
        }

        if (timeoutExec) {
            clearTimeout(timeoutExec)
        }

        setHasUnsavedChanges(true)

        const exec = setTimeout(() => {
            setIsReportFetching(true)
            axios({
                method: 'POST',
                url: `${recipe_report_url}/${report.id}`,
                data: report
            }).then(res => {
                if (fetch) {
                    setReport(res.data)
                }

                setError(null)
            }).catch(err => {
                setError(err.message)
            }).finally(() => {
                setIsReportFetching(false)
                setHasUnsavedChanges(false);
            })

        }, delay)

        setTimeoutExec(exec)
    }

    return {
        report,
        handleReportChange,
        hasUnsavedChanges,
        error,
    }

}

export default useRecipeReport