import axios from "axios";
import {host} from "../config/api";

export const deleteIngredient = (id) => {

    return axios({
        method: 'delete',
        url: `${host}/v1/nutrical-ingredients/${id}`
    })
}

export const createIngredient = (ingredient) => {
    return axios({
        method: 'post',
        url: `${host}/v1/nutrical-ingredients`,
        data: ingredient
    })
}

export const updateIngredient = (ingredient) => {
    return axios({
        method: 'post',
        url: `${host}/v1/nutrical-ingredients/${ingredient.id}`,
        data: ingredient
    })
}