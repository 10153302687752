import React, {useState} from "react"
import "../css/nutrientDeclarationTable.css"
import {Unit} from "../containers/RecipeContainer";
import useNutricalValues from "../hooks/useNutricalValues";
import {isValid} from "../service/RecipeService";
import NutritionTable from "./NutritionTable";


const NutrientDeclarationTable = (props) => {
    const {recipe} = props

    const [isFetched, setIsFetched] = useState(false)
    const [nutricalValues, refreshNutricalValues, isNutricalValuesLoading, nutricalValuesError] = useNutricalValues()

    if (recipe && !isFetched) {

        refreshNutricalValues(recipe)
        setIsFetched(true)
    }

    if (!recipe || !nutricalValues || !nutricalValues.nutrient_standard || !nutricalValues.nutrient_standard.nutrient_basic) {
        return
    }

    const nutrientBasic = nutricalValues.nutrient_standard.nutrient_basic

    return (
        <NutritionTable nutricalValues={nutricalValues}
                        nutrientFlag={recipe.nutrient_flag}
                        setNutrientFlag={() => {}}
                        onNutrientFlagChange={() => {}}
                        isLoading={isNutricalValuesLoading}
                        unit={recipe.default_unit}
                        isValid={() => isValid(recipe)}
                        portionAmount={recipe.portion_amount}
                        isDeclaration={true}/>
    )

    // return (
    //     <div className="nutrical-preview-table">
    //         <table>
    //             <tbody>
    //             <tr>
    //                 <td className="rotate" rowSpan={9}><div>Prosečna hranljiva vrednost</div></td>
    //                 <td></td>
    //                 <td className="amount">{recipe.default_unit === Unit.ML ? '100ml' : '100g'}</td>
    //
    //             </tr>
    //             <tr>
    //                 <td className="name pl-8">Energija</td>
    //                 <td className="amount">
    //                     <div>{nutrientBasic.energy_kj} KJ</div>
    //                     <div>{nutrientBasic.energy_kcal} kcal</div>
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <td className="name pl-8">Masti</td>
    //                 <td className="amount">{nutrientBasic.fat} g</td>
    //             </tr>
    //             <tr>
    //                 <td className="name pl-18">od kojih zasicene masne kiseline</td>
    //                 <td className="amount">{nutrientBasic.fat_saturated} g</td>
    //             </tr>
    //             <tr>
    //                 <td className="name pl-8">Ugljeni hidrati</td>
    //                 <td className="amount">{nutrientBasic.carbohydrate} g</td>
    //             </tr>
    //             <tr>
    //                 <td className="name pl-18">od kojih šećeri</td>
    //                 <td className="amount">{nutrientBasic.sugar} g</td>
    //             </tr>
    //
    //             <tr>
    //                 <td className="name pl-8">Proteini</td>
    //                 <td className="amount">{nutrientBasic.protein} g</td>
    //             </tr>
    //             <tr>
    //                 <td className="name pl-8">So</td>
    //                 <td className="amount">{nutrientBasic.salt} g</td>
    //             </tr>
    //             </tbody>
    //         </table>
    //     </div>
    // )
}

export default NutrientDeclarationTable