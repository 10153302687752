import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import axios from "axios";
import Button from "./Button";
import {UploadIcon, XCircleIcon} from "../icons";
import classNames from "classnames";
import {MEDIA_API} from '../config/api';


const Tag = ({fileName, isLoading, hasErrors, onRemove, className}) => {

    const rootClassNames = classNames(className, "mt-2 mr-20 flex items-center h-[32px] rounded-full pl-4 pr-1 text-sm", {
            "bg-gray-100": !hasErrors,
            "bg-red-50": hasErrors,
            "border-red-600": hasErrors,
            "text-red-600": hasErrors,
            "border": true
    })

    const xCircleClassNames = classNames("ml-2 cursor-pointer")

    return (
        <div className="nowrap">
            <div className={rootClassNames}>
                <span className="nowrap">{fileName}</span>
                {isLoading && <span> (Loading...)</span>}
                {!hasErrors && <XCircleIcon width={15} className={xCircleClassNames} onClick={onRemove}/>}
                {hasErrors && <XCircleIcon width={15} fill="red" className={xCircleClassNames} onClick={onRemove}/>}
            </div>
            <div>
                {hasErrors && <span className="text-red-500 ml-2 text-xs"> Greska: neispravan format</span>}
            </div>
        </div>
    )
}


const FileUpload = ({nutricalRequest, setNutricalRequest}) => {

    const onUploadStart = useCallback((file) => {
        setNutricalRequest((prevState) => {
            return {
                ...prevState,
                nutrients: [
                    ...prevState.nutrients,
                    {
                        uploading: {
                            file,
                            is_loading: true,
                            has_error: false
                        }
                    }
                ]
            }
        })
    }, [])

    const onUploadEnd = useCallback((file, response, error) => {
        setNutricalRequest((prevState) => {
            let nutrients = prevState.nutrients.map((nutrient) => {
                if (nutrient.uploading.file === file) {
                    return {
                        storage: response ? {...response.data} : null,
                        uploading: {
                            file: file,
                            is_loading: false,
                            has_error: error
                        }
                    }
                }

                return nutrient
            })

            return {
                ...prevState,
                nutrients: nutrients
            }
        })
    }, [])

    const onDrop = useCallback(async (acceptedFiles) => {

        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            const config = {headers: {'Content-Type': 'multipart/form-data'}};
            const formData = new FormData();
            formData.append("file", file);

            try {
                onUploadStart(file)
                const response = await axios.post(MEDIA_API, formData, config);
                onUploadEnd(file, response, false)

            } catch (e) {
                onUploadEnd(file, null, true)
            }
        }

    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop});

    const removeFile = (file) => {
        setNutricalRequest((prevState) => {
            console.log(prevState)
            let nutrients = prevState.nutrients.filter((nutrient) => nutrient.uploading.file !== file)

            return {
                ...prevState,
                nutrients: nutrients
            }
        })
    }

    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p className="text-sm">Privuci fajl ili selektuj sa svog računara</p>
                <Button className="mt-6" color="neutral" size="large" startIcon={<UploadIcon/>}>Selektuj</Button>
            </div>
            <aside>
                <div className="flex flex-wrap">
                    {nutricalRequest && nutricalRequest.nutrients && nutricalRequest.nutrients.map((nutrient, index) => (

                        <Tag fileName={nutrient.uploading.file.name}
                             onRemove={() => removeFile(nutrient.uploading.file)}
                             hasErrors={nutrient.uploading.has_error }
                             isLoading={nutrient.uploading.is_loading}/>
                    ))}
                </div>
            </aside>
        </section>
    )
}

export default FileUpload