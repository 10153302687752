import axios from "axios";
import {recipe_report_by_id_url, recipe_report_download_url, recipe_report_generate_url} from "../config/api";

export const getReport = async (requestId) => {
    return axios({
        method: 'GET',
        url: `${recipe_report_by_id_url}/${requestId}`
    })
}

export const downloadPDF = async (recipeId, docType) => {
    return axios({
        method: 'GET',
        url: `${recipe_report_generate_url}/${recipeId}`,
        params: {
            type: docType
        },
        responseType: 'blob'
    }).then(response => {
        return new Blob([response.data], { type: 'application/pdf' });
    })
}

export const downloadReport = async (recipeId, requestId) => {
    return axios({
        method: 'GET',
        url: `${recipe_report_download_url}/${recipeId}`,
        responseType: 'blob'
    }).then(response => {
        const pdf = new Blob([response.data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(pdf);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${requestId}-izveštaj.zip`);
        document.body.appendChild(link);
        link.click();
    })
}