import React from "react"
import Button from "./Button";

import "../css/nextStep.css"
import {ArrowRightIcon} from "../icons";

const NextStep = ({onNext, hide, isValid}) => {

    if (hide || !isValid()) {
        return
    }

    return (
        <div className="next-step-wrapper">
            <div className="next-step text-primary text-sm">
                <div>
                    <span>Sledeći korak:</span>
                    <span className="font-medium">Korekcija sastojaka i prikaz deklaracije</span>
                </div>
                <Button className="absolute right-4" onClick={onNext}
                        size="medium"
                        color="primary"
                        endIcon = {<ArrowRightIcon width={16} height={16}/>}>Dalje</Button>
            </div>
        </div>
    )
}

export default NextStep