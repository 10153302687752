import React from "react"

const RadioBtnMark = ({selected, }) => {

    return (
        <div className="flex bg-unchecked w-[24px] h-[24px] items-center justify-center flex-grow-0 flex-shrink-0 rounded-full">
            {selected && <div className="w-[15px] h-[15px] bg-checked rounded-full"></div>}
        </div>
    )
}

export default RadioBtnMark