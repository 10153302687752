import React, {useEffect, useState} from "react";
import { Document, Page, pdfjs } from 'react-pdf';

import "../fonts/Inter-SemiBold";
import "../css/pdfPreviewContainer.css"
import Button from "../components/Button";
import Loader from "../components/Loader";
import useUrlParams from "../hooks/useUrlParams";
import Navigation from "../components/Navigation";
import useRecipeReport, {DocType} from "../hooks/useRecipeReport";
import {BackIcon, DownloadIcon, SaveIcon} from "../icons";
import {downloadPDF} from "../service/ReportService";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;

const PdfPreview = () => {

    const {recipeId, requestId, docType} = useUrlParams();
    const [pdf, setPdf] = useState(null)
    const [numPages, setNumPages] = useState(0)

    const [docWidth, setDocWidth] = useState(600)

    useEffect(() => {
        downloadPDF(recipeId, docType || DocType.REGULAR).then(response => {
            setPdf(response)
        })

    }, [])

    const handlePdfLoadSuccess = ({numPages}) => {
        setNumPages(numPages)
    }

    const onPdfDownloadBtnClick = async () => {

        let fileName = `${requestId}-${docType === DocType.DECLARATION ? 'etiketa': 'izveštaj'}.pdf`
        const url = window.URL.createObjectURL(pdf);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    const onBack = () => {
        window.location.href = `/report?requestId=${requestId}`
    }

    const handleDocWidthChange = (width) => {

        if (width <= 0) {
            return
        }

        setDocWidth(width)
    }

    return (
        <Loader isLoading={!pdf}>
            <div className="pdf-preview-container">
                    <Navigation page="recipes"/>
                {/*<div className="w-100-pct">*/}
                {/*    <Button onClick={onBack}*/}
                {/*            size="medium"*/}
                {/*            color="white"*/}
                {/*            startIcon={<img src="/back.svg"/>}>Nazad</Button>*/}
                {/*</div>*/}
                {   pdf &&
                    <div className="pdf-container pt-[30px] pb-[100px]">
                        <div className="title">

                            { docType !== DocType.DECLARATION &&
                                <div className="font-medium text-primary text-[26px] mr-4">Pregled izveštaja</div>
                            }

                            { docType === DocType.DECLARATION &&
                                <div className="font-medium text-primary text-[26px] mr-4">Pregled etikete</div>
                            }

                            <Button color="primary"
                                    onClick={onPdfDownloadBtnClick}
                                    startIcon={<DownloadIcon/>}>
                                {docType !== DocType.DECLARATION && <span>Preuzmi izveštaj</span>}
                                {docType === DocType.DECLARATION && <span>Preuzmi etiketu</span>}
                            </Button>

                        </div>
                        <div className="content border-2 border-white">
                            <Document file={pdf} pageLayout="oneColumn"
                                      options={{workerSrc: "pdf.worker.js"}}
                                      onLoadSuccess={handlePdfLoadSuccess}>

                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} wrap={false} width={docWidth}/>
                                ))}
                            </Document>
                        </div>
                    </div>
                }

                {
                    pdf &&
                    <div className="flex items-center fixed bottom-2 z-20 border border-black bg-opacity-1 w-[100px] py-1 justify-center rounded-xl">

                        <div className="bg-report-header w-8 h-8 rounded-full text-white flex items-center justify-center font-semibold hover:cursor-pointer hover:opacity-80" onClick={() => handleDocWidthChange(docWidth + 100)}>+</div>
                        <div className="bg-gray-100 ml-4 w-8 h-8  rounded-full text-gray-900 flex items-center justify-center font-semibold hover:cursor-pointer hover:opacity-100" onClick={() => handleDocWidthChange(docWidth - 100)}>-</div>
                    </div>
                }

                <div className="flex space-between items-center grow h-[56px] bg-white px-4 fixed bottom-0 left-0 right-0">
                    <Button onClick={onBack}
                            className="border border-black" color="white">
                        <BackIcon/>
                        <span className="ml-1">Nazad</span>
                    </Button>
                </div>
            </div>
        </Loader>
    )
}

export default PdfPreview