import React from "react"
import Button from "./Button";

import "../css/deleteModal.css"
import ModalCloseBtn from "../icons/ModalCloseBtn";

const DeleteModal = ({open, title, message, onClose, onDelete, itemName}) => {

    if (!open) {
        return
    }

    return (
        <div className="delete-modal">
            <div className="box">
                <div className="modal-close-btn" onClick={onClose}><ModalCloseBtn/></div>
                <div className="header">
                    <img src="/delete-modal.svg" />
                </div>
                <div className="title">{title} '{itemName}'</div>
                <div className="message">{message}</div>
                <div className="footer">
                    <Button size="large" color="white" className="mr-20" onClick={onClose}>Odustani</Button>
                    <Button size="large" color="red" className="mr-20" onClick={onDelete}>Obriši</Button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal