import axios from "axios";
import {recipe_comments_url} from "../config/api";
import {useState} from "react";

export const useRecipeComments = (recipeId) => {

    const [comments, setComments] = useState(null)

    if (recipeId && !comments) {
        axios({
            method: 'GET',
            url: `${recipe_comments_url}/recipe/${recipeId}`,
        }).then((response) => {
            const comments = response.data && response.data.reverse()
            setComments(comments)
        })
    }

    const postComment = (name, description, recipeId) => {
        axios({
            method: 'POST',
            url: recipe_comments_url,
            data: {
                name: name,
                description: description,
                recipe: {
                    id: recipeId
                }
            }
        }).then(() => {
            setComments(null)
        })
    }

    const deleteComment = (commentId) => {
        axios({
            method: 'DELETE',
            url: `${recipe_comments_url}/${commentId}`
        }).then(() => {
            setComments(null)
        })
    }

    const editComment = (commentId, name, description, recipeId) => {
        axios({
            method: 'POST',
            url: `${recipe_comments_url}/${commentId}`,
            data: {
                id: commentId,
                name: name,
                description: description,
                recipe: {
                    id: recipeId
                }
            }
        }).then(() => {
            setComments(null)
        })
    }

    return [
        comments,
        postComment,
        deleteComment,
        editComment
    ]
}