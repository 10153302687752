import React, {useEffect, useRef, useState} from "react"
import "../css/contentEditable.css"
import classNames from "classnames";

export const Command = {
    BOLD: "bold",
    ITALIC: "italic",
    UNDERSCORE: "u"
}

const ContentEditable = ({onChange, rootClasses, textAreaClasses, value, label}) => {


    const [isEmpty, setIsEmpty] = useState(true)
    const [displayCommand, setDisplayCommand] = useState(false)
    const [commandLeftPos, setCommandLeftPos] = useState(null)
    const [commandTopPos, setCommandTopPos] = useState(null)

    const contentEditableRef = useRef(null)

    useEffect(() => {
        let ceRef = contentEditableRef.current

        ceRef.innerHTML = value || ""

        const hideEditCommand = (e) => {
            if (ceRef && !ceRef.contains(e.target)) {
                setDisplayCommand(false)
            }
        }

        document.addEventListener("click", hideEditCommand)

        ceRef.addEventListener("mouseup", onSelectionChangeHandler)

        return () => {
            ceRef && ceRef.removeEventListener("mouseup", onSelectionChangeHandler)
            document.removeEventListener("click", hideEditCommand)
        }
    }, [])

    const onSelectionChangeHandler = () => {
        let selection = window.getSelection();
        let range = selection.getRangeAt(0);

        if (range.startContainer === range.endContainer && range.startOffset === range.endOffset) {
            setDisplayCommand(false)
            return
        }

        let textarea= contentEditableRef.current
        if (!textarea.contains(range.commonAncestorContainer)) {
            return
        }

        let rect = range.getBoundingClientRect();
        let textareaRange = textarea.getBoundingClientRect();

        setCommandLeftPos(rect.left - textareaRange.left)
        setCommandTopPos(rect.top - textareaRange.top - 35)
        setDisplayCommand(true)
    }

    const handleTextChange = () => {
        setIsEmpty(true)

        if (contentEditableRef.current.textContent && contentEditableRef.current.textContent.trim().length > 0) {
            setIsEmpty(false)
        }

        if (typeof onChange === "function") {
            onChange(contentEditableRef.current.innerHTML)
        }
    }

    const handlePaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
        onChange(contentEditableRef.current.innerHTML)
    }

    const editText = (tag) => {
        document.execCommand("bold")
    }

    const commandStyle = {
        left: commandLeftPos + "px",
        top: commandTopPos + "px",
    }

    const classes = classNames(textAreaClasses, {
        "text-area": true,
        "text-area-empty": isEmpty
    })

    return (
        <div className={`content-editable ${rootClasses}`} >
            { label &&
                <div className="text-sm text-primary">{label}</div>
            }
            <div
                contentEditable={true}
                className={classes}
                id="text-area"
                onInput={handleTextChange}
                ref={contentEditableRef}
                onPaste={handlePaste}>
            </div>
            {
                displayCommand &&
                <div id="command" style={commandStyle} onClick={() => editText(Command.BOLD)}>
                    <button>B</button>
                </div>
            }
        </div>
    )
}

export default ContentEditable