import React from "react"
import Modal from "./Modal";
import {HamburgerIcon, PackegingTypeIcon, ShoppingBasketIcon, WeightScaleIcon} from "../icons";
import Button from "./Button";


const Item = ({icon, name, value}) => {
    return (
        <div className="flex space-between items-center text-primary text-sm mb-[20px]">
            <div className="flex items-center">
                {icon}
                <div className="ml-2 ">{name}</div>
            </div>
            <div className="font-medium">
                {value}
            </div>
        </div>
    )
}

const RecipeDetailsModal = ({show, nutricalRequest, onClose}) => {

    return (
        <Modal contentClassName="flex basis-[550px] bg-white"
               show={show}
               onClose={onClose}>

            <Modal.Header>Info o proizvodu</Modal.Header>

            <Modal.Body>
                <Item icon={<HamburgerIcon/>} name="Naziv proizvoda" value={nutricalRequest.recipe_name}/>
                <Item icon={<ShoppingBasketIcon/>} name="Vrsta" value={nutricalRequest.recipe_category}/>
                <Item icon={<PackegingTypeIcon/>} name="Način pakovanja" value={nutricalRequest.recipe_packing_type}/>
                <Item icon={<WeightScaleIcon/>} name="Neto količina pakovanja" value={nutricalRequest.recipe_net_amount}/>
            </Modal.Body>

            <Modal.Footer isValid={true}>
                <div className="flex justify-center grow">
                    <Button color="primary" size="large" onClick={onClose}>U redu</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default RecipeDetailsModal