import React, {useState} from "react"

import "../css/ingredientTable.css"
import NumberField from "./NumberField";
import {CreateIcon, ThermometerIcon, TrashIcon, XCircleIcon} from "../icons";
import IngredientInfo from "./IngredientInfo";
import RetentionFactorPicker from "./RetentionFactorPicker";

const RetentionFactorBtn = ({langualCode, disabled, onChange, defaultRetentionFactors}) => {

    const [showPicker, setShowPicker] = useState(false)

    const handleThermometerClick = () => {
        if (!disabled) {
            setShowPicker(true)
        }
    }

    const handleDeleteRetentionFactor = (id) => {
        onChange(defaultRetentionFactors.filter(e => e?.id !== id))
    }

    return (
        <div>
            <div className="flex items-center">
                <div className={`bg-secondary-action w-[60px] h-[40px] rounded-md 
                             shrink-0
                             flex align-center justify-center
                             ${disabled ? 'bg-opacity-40' : 'hover:cursor-pointer hover:bg-opacity-80'}`}
                     onClick={handleThermometerClick}>
                    <CreateIcon/>
                    <ThermometerIcon className="ml-1"/>

                </div>
                <div className="flex flex-col ml-2 overflow-hidden items-start">
                    {
                        (!defaultRetentionFactors || defaultRetentionFactors.length === 0) &&
                        <div className="text-xs text-light">Trenutno nema izabranih metoda termičke obrade</div>
                    }
                    {
                        defaultRetentionFactors && defaultRetentionFactors.sort((a, b) => a.id - b.id).map(retentionFactor => (
                            <div key={retentionFactor?.retention_factor?.id} className="group flex items-center text-xs bg-table-info pl-3 pr-1 py-1
                                            text-table-info rounded-[32px] mt-1 whitespace-nowrap text-ellipsis
                                            overflow-hidden max-w-full
                                            hover:cursor-pointer border
                                            hover:border-error
                                            hover:text-error"
                            onClick={() => handleDeleteRetentionFactor(retentionFactor?.id)}>
                                <span
                                    className="text-ellipsis overflow-hidden">{retentionFactor?.retention_factor?.cooking_method_langual_description}</span>
                                <XCircleIcon className="ml-2 w-[15px] shrink-0 group-hover:fill-error"/>
                            </div>
                        ))
                    }
                </div>
            </div>
            {showPicker && <RetentionFactorPicker langualCode={langualCode}
                                                  onClose={() => setShowPicker(false)}
                                                  onChange={(retentionFactors) => {
                                                      onChange(retentionFactors)
                                                      setShowPicker(false)
                                                  }}
                                                  defaultRetentionFactors={defaultRetentionFactors}/>}
        </div>
    )
}

const EmptyTable = ({recipe}) => {

    if (recipe && recipe.ingredients && recipe.ingredients.length > 0) {
        return
    }

    return (
        <div className="recipe-empty-section">
            <div>
                <img src="/iconBookBold.svg"/>
                <div>Počni sa dodavanjem sastojaka</div>
            </div>
        </div>
    )
}


const DELAY = 2000

const IngredientTable = props => {

    const {recipe, refreshNutricalValues, updateRecipe} = props

    const handleAmountChange = (index, e) => {

        let recipeLocal = Object.assign({}, recipe);
        recipeLocal.ingredients[index].amount = e.target.value
        updateNetAmount(recipeLocal)

        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: false,
            delay: DELAY
        })

        refreshNutricalValues(recipeLocal)
    }

    const handleRetentionFactorsChange = (recipeIngredientId, retentionFactors) => {
        let recipeLocal = Object.assign({}, recipe);
        recipeLocal.ingredients = recipeLocal.ingredients.map(recipeIngredient => {
            if (recipeIngredient.id === recipeIngredientId) {
                return ({
                    ...recipeIngredient,
                    retention_factors: retentionFactors
                })
            }

            return recipeIngredient
        })

        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: true,
            delay: 0
        })

        refreshNutricalValues(recipeLocal)
    }

    const updateNetAmount = (recipeLocal) => {
        recipeLocal.yield_factor = recipeLocal.yield_factor ? recipeLocal.yield_factor : 1
        recipeLocal.prepared_amount = calculateNetAmount() * recipeLocal.yield_factor
        recipeLocal.portion_count = ""
        recipeLocal.portion_amount = ""
    }

    const getIngredients = () => {
        return recipe.ingredients || []
    }

    const sum = (array = []) => {
        let total = 0
        for (let num of array) {
            total += num
        }

        return total
    }

    const calculateNetAmount = () => {
        return sum(getIngredients().filter(Boolean).map(e => e.amount).map(Number))
    }


    const onDelete = (index) => {
        let recipeLocal = Object.assign({}, recipe);
        recipeLocal.ingredients.splice(index, 1)
        updateNetAmount(recipeLocal)

        updateRecipe({
            recipe: recipeLocal,
            push: true,
            fetch: false,
            delay: 0
        })

        refreshNutricalValues(recipeLocal)
    }

    return (
        <div className="ingredient-table">
            <table>
                <thead>
                <tr className="text-light">
                    <th className="">Naziv sastojka</th>
                    <th className="w-[100px]">Masa (g)</th>
                    <th className="w-25-pct-md">Termička obrada</th>
                    <th className="w-[60px]"></th>
                </tr>
                </thead>
                {recipe && recipe.ingredients && <tbody className={recipe.ingredients.length ? 'space' : ''}></tbody>}
                <tbody>
                {
                    recipe && recipe.ingredients && recipe.ingredients.map((ingredient, index) => (
                        <tr key={ingredient.id + ingredient.ingredient_type}>
                            <td>
                                <div className="font-medium text-sm text-primary">{ingredient.ingredient.name}</div>
                                <div className="text-xs text-light flex items-center">
                                    <div>
                                        Energija {ingredient?.ingredient?.nutrient_basic?.energy_kj || 0} KJ
                                    </div>
                                    <div>, {ingredient?.ingredient?.nutrient_basic?.energy_kcal || 0} kcal</div>
                                    <div className="mx-1">|</div>
                                    <div>Masti {ingredient?.ingredient?.nutrient_basic?.fat || 0} g</div>
                                    <div className="mx-1">|</div>
                                    <div>Ugljeni hidrati {ingredient?.ingredient?.nutrient_basic?.carbohydrate || 0} g</div>
                                    <div className="mx-1">|</div>
                                    <div>Proteini {ingredient?.ingredient?.nutrient_basic?.protein || 0} g</div>
                                    <IngredientInfo ingredient={ingredient?.ingredient}/>
                                </div>
                            </td>
                            <td>
                                <NumberField isDecimal={true} value={ingredient.amount || ""}
                                             onChange={(e) => handleAmountChange(index, e)} textRight="g"/>
                            </td>
                            <td>
                                <RetentionFactorBtn langualCode={ingredient?.ingredient?.langual_code}
                                                    disabled={!ingredient?.ingredient?.langual_has_retention_factors}
                                                    onChange={(retentionFactors) => handleRetentionFactorsChange(ingredient.id, retentionFactors)}
                                                    defaultRetentionFactors={ingredient?.retention_factors}/>
                            </td>
                            <td className="text-align-right">
                                <span className="flex justify-end mr-[15px] ">
                                    <TrashIcon onClick={() => onDelete(index)}
                                               className=" hover:cursor-pointer hover:stroke-red-900"/>
                                </span>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <EmptyTable recipe={recipe}/>
        </div>
    )
}

export default IngredientTable