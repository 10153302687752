import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import NutricalRequestsContainer from "./containers/NutricalRequestsContainer";
import ReportContainer from "./containers/ReportContainer";
import RecipeContainer from "./containers/RecipeContainer";
import PdfPreview from "./containers/PdfPreview";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./security/Keycloak";
import PrivateRoute from "./security/PrivateRoute";
import FontProvider from "./components/FontProvider";


const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <PrivateRoute><NutricalRequestsContainer/></PrivateRoute>,
    },
    {
        path: "/requests",
        element: <PrivateRoute><NutricalRequestsContainer/></PrivateRoute>,
    },
    {
        path: "/recipe",
        element: <PrivateRoute><RecipeContainer/></PrivateRoute>,
    },
    {
        path: "/report",
        element: <PrivateRoute><ReportContainer/></PrivateRoute>,
    },
    {
        path: "/report/preview",
        element: <PrivateRoute><PdfPreview/></PrivateRoute>
    }
]);



root.render(
    <ReactKeycloakProvider initOptions={{onLoad: 'login-required'}} authClient={keycloak}>
        <FontProvider>
            <RouterProvider router={router}/>
        </FontProvider>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
