import React, {useEffect, useState} from "react"

import "../css/checkbox.css"
import classNames from "classnames";
import {CheckboxIcon} from "../icons";

export const Variant = {
    ORANGE: {
        name: "ORANGE",
        bgChecked: "#FC634B",
        bgUnchecked: "#DBDBDB",
        bgDisabled: "#DBDBDB",
        strokeChecked: "#FFFFFF",
        strokeDisabled: "#A0A0A0",
    },

    RED:  {
        name: "RED",
        bgChecked: "#D71212",
        bgUnchecked: "#DBDBDB",
        strokeChecked: "#FFFFFF",
        strokeDisabled: "#A0A0A0",
    }
}

// todo DEPRICATED use CheckboxInput component
const Checkbox = ({ disabled = false, checked = true, defaultChecked, onClick, variant = Variant.ORANGE}) => {

    const [isChecked, setIsChecked] = useState(checked)

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])


    const checkboxClasses = classNames({
        "checkbox inline-flex": true,
        "disabled": disabled,
        "checked": checked,
    })

    const markStyle = {
        backgroundColor: disabled ? variant.bgDisabled : checked ? variant.bgChecked : variant.bgUnchecked
    }

    const iconStyle = {
        stroke: disabled ? variant.strokeDisabled : variant.strokeChecked
    }

    const handleOnChange = () => {
        if (!disabled) {
            setIsChecked((prevState) => !prevState)
        }
    }

    return (
        <div className={checkboxClasses} onClick={onClick}>
            <input type="checkbox"
                   checked={isChecked}
                   onChange={handleOnChange} />

            <span className="mark" style={markStyle}>
                {
                    (checked || defaultChecked) && <CheckboxIcon style={iconStyle}  width={10} height={7} src="/checkbox.svg"/>
                }
            </span>
        </div>
    )
}

export default Checkbox