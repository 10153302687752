import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import {Search} from "../components/Search";
import NutricalRequestTable from "../components/NutricalRequestTable";
import "../css/nutricalRequestContainer.css";
import useNutricalRequests from "../hooks/useNutricalRequests";
import ReactPaginate from "react-paginate";
import NextPage from "../icons/NextPage";
import PrevPage from "../icons/PrevPage";
import {NUMBER_REGEX} from "../config/config";
import Button from '../components/Button';
import NutricalRequestFormModal from '../components/NutricalRequestFormModal';

const NutricalRequestsContainer = props => {

    let [params, setParams] = useState({
        page: 0,
        "search-text": "",
        size: 10,
    })
    let [nutricalRequests, setNutricalRequests, refreshNutricalRequests, isLoading,
        error, deleteNutricalRequest, totalPages] = useNutricalRequests()

    const [pageInput, setPageInput] = useState("")
    const [showNutricalRequestForm, setShowNutricalRequestForm] = useState(false);



    useEffect(() => {
        refreshNutricalRequests(params)
    }, [])

    const handleSearchChange = (value) => {
        let localParams = {
            ...params,
            "search-text": value,
            page: 0,
        }
        setParams(localParams)
        refreshNutricalRequests(localParams)
    }

    const handlePageInputKeyUp = (e) => {
        if (e.key === 'Enter') {
            handlePageInputChange(pageInput)
        }
    }

    const handlePageInputChange = (value) => {
        let page = value

        if (!page) {
            setPageInput(page)
            return
        }

        if (page > totalPages) {
            page = 1;
        }


        setPageInput(page)
        handlePageChange(page - 1)
    }

    const handlePageChange = (page) => {

        if (!page) {
            page = 0
        }

        let localParams = {
            ...params,
            page: page
        }

        setPageInput("")
        setParams(localParams)
        refreshNutricalRequests(localParams)
    }

    return (
        <div className="nutrical-request">
            <Navigation page="requests"/>
            <div className="content w-100-pct-sm w-80-pct-lg w-60-pct-xl">
                <div className="flex space-between items-center w-full mt-8 mb-4">
                    <div className="font-semibold text-primary text-[18px]">Lista zahteva</div>
                    <Button color="secondary" size="large" onClick={() => setShowNutricalRequestForm(true)}>+ Kreiraj novi zahtev</Button>
                </div>
                <Search value={params["search-text"]} onChange={handleSearchChange}/>
                <NutricalRequestTable data={nutricalRequests} isLoading={isLoading} onDelete={deleteNutricalRequest}/>
                {
                    totalPages &&
                    <div className="flex space-between items-center w-full  bg-white rounded-[12px]
                    mt-4 mb-8 h-[46px] px-4 text-xs">
                        <div className="basis-[200px]">Strana {params.page + 1} od {totalPages}</div>
                        <ReactPaginate

                            className="flex grow justify-center items-center]"
                            pageClassName="w-[26px] h-[26px] flex items-center justify-center text-xs"
                            activeClassName="bg-body rounded-[8px] font-semibold text-sm"
                            nextClassName="flex items-center ml-4"
                            previousClassName="flex items-center mr-4"
                            breakLabel="..."
                            nextLabel={<NextPage/>}
                            previousLabel={<PrevPage/>}
                            onPageChange={(e) => handlePageChange(e.selected)}
                            pageRangeDisplayed={5}
                            pageCount={totalPages}
                            renderOnZeroPageCount={null}
                            forcePage={params.page}
                        />
                        <div className="basis-[200px] flex items-center justify-end">
                            <div>Idi na stranu:</div>
                            <input className="w-[32px] h-[26px] ml-2 bg-input rounded-[6px] text-center" type="text"
                                   pattern={NUMBER_REGEX}
                                   value={pageInput}
                                   onChange={(e) => setPageInput(e.target.value)}
                                   onBlur={(e) => handlePageInputChange(e.target.value)}
                                   onKeyUp={handlePageInputKeyUp}/>
                        </div>
                    </div>
                }
            </div>
            {showNutricalRequestForm && <NutricalRequestFormModal onClose={() => {
                setShowNutricalRequestForm(false)
                refreshNutricalRequests(params)
            }} />}
        </div>
    )
}

export default NutricalRequestsContainer;