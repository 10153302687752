import React, {useState} from "react";

import "../css/recipeComment.css"
import Modal from "./Modal";
import TextAreaField from "./TextAreaField";
import Button from "./Button";
import {useRecipeComments} from "../hooks/useRecipeComments";
import ColorHash from 'color-hash';
import {format} from "date-fns";
import SvgArrowRightIcon from "../icons/ArrowRightIcon";
import classNames from "classnames";


const Avatar = ({createdBy}) => {

    const colorHash = new ColorHash();
    const backgroundColor = colorHash.hex(createdBy);

    const avatarStyle = {
        backgroundColor: backgroundColor
    }

    const nameParts = createdBy.split(" ")

    if (!nameParts) {
        return <div className="w-[24px] h-[24px] rounded-full bg-gray-800 flex items-center justify-center"><Avatar/></div>
    }

    if (nameParts.length === 1) {
        return <div className="w-[24px] h-[24px] text-sm font-medium rounded-full text-white mr-2 flex-grow-0 flex-shrink-0 flex items-center justify-center"
                    style={avatarStyle}>
            <span className="uppercase">{nameParts[0][0]}</span>
        </div>
    }

    return <div className="w-[24px] h-[24px] text-sm font-medium rounded-full text-white mr-2 flex-grow-0 flex-shrink-0 flex items-center justify-center"
                style={avatarStyle}>
        <span className="uppercase">{nameParts[0][0]}</span>
        <span className="uppercase">{nameParts[1][0]}</span>
    </div>
}

const Comment = ({createdBy, createdAt, message, messageClassNames,
                  displayCreatedBy = false,
                  lightMode=true}) => {

    const messageRootClasses = classNames({
            "flex flex-col items-start  grow text-sm text-primary relative top-[-2px] w-full rounded-tr-[8px] rounded-br-[8px] rounded-bl-[8px]": true,
            "bg-white": lightMode,
            "bg-input px-[8px] pt-[8px] pb-[5px]": !lightMode
    })

    const footerClasses = classNames({
        "flex grow space-between items-center w-full": true,
        "mt-2": !lightMode
    })

    return (
        <div className="flex mb-4 items-start">
            <Avatar createdBy={createdBy}/>
            <div className={messageRootClasses}>
                <div className="">{message}</div>
                <div className={footerClasses}>
                    {displayCreatedBy &&  <div className="grow text-xs text-light">{createdBy}</div>}
                    <div className="text-xs text-light flex items-center justify-center">
                        <div>{createdAt ? format(new Date(createdAt), "dd.MM.yyyy") : ""}</div>
                        <div className="ml-2">{createdAt ? format(new Date(createdAt), "HH:mm") : ""}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const RecipeComment = ({recipeId}) => {

    const [displayNoteModal, setDisplayNoteModal] = useState(false)

    const [commentId, setCommentId] = useState(null)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    const [comments, postComment, deleteComment, editComment] = useRecipeComments(recipeId)

    if (!recipeId) {
        return
    }

    const onSubmit = () => {

        const validation = validateForm()
        if (!validation.isValid) {
            return
        }

        if (commentId) {
            editComment(commentId, title, description, recipeId)
        } else {
            postComment(title, description, recipeId)
        }

        clearForm()
        setDisplayNoteModal(false)
    }

    const onEditComment = (id, title, description) => {
        setCommentId(id)
        setTitle(title)
        setDescription(description)
        setDisplayNoteModal(true)
    }

    const onDelete = (commentId) => {
        deleteComment(commentId)
        setCommentId(null)
        setTitle("")
        setDescription("")
    }

    const onModalClose = () => {
        setCommentId(null)
        setTitle("")
        setDescription("")
        setDisplayNoteModal(false)
    }

    const validateForm = () => {
        let validation = {
            isValid: true,
            description: {
                errorMsg: ""
            }
        }

        if (!description) {
            validation.isValid = false
        }

        if (title && title.length < 3) {
            validation.isValid = false
            validation.description.errorMsg = "Minimalni broj karaktera: 3"
        }

        return validation
    }

    const clearForm = () => {
        setCommentId(null)
        setTitle("")
        setDescription("")
    }



    return (
        <div className="RecipeComment">

            <div className="p-4">
                <div>
                    {
                        comments && comments.map(comment => <Comment createdBy={comment.created_by}
                                                                     createdAt={comment.created_at}
                                                                     message={comment.description}/>)
                    }
                </div>
                <Button className="mt-4 w-full flex items-center"
                        onClick={() => setDisplayNoteModal(true)}
                        color="secondary">
                    <span className="text-[18px] mr-2 font-light">+</span>
                    <span>Kreiraj novu belešku</span>
                </Button>
            </div>

            <Modal onClose={onModalClose} show={displayNoteModal} contentClassName="w-550 bg-white-shade">
                {!commentId && <Modal.Header>Kreiraj belešku</Modal.Header>}
                {commentId && <Modal.Header>Izmeni belešku</Modal.Header>}
                <Modal.Body className="relative">

                    <div className="mb-[200px]">
                        {
                            comments && comments.map(comment => <Comment createdBy={comment.created_by}
                                                                         createdAt={comment.created_at}
                                                                         message={comment.description}
                            displayCreatedBy={true} lightMode={false}/>)
                        }
                    </div>

                    <div className="bg-white absolute bottom-0 left-0 right-0 p-[15px] shadow-modal-footer rounded-b-[16px] ">
                        <TextAreaField value={description}
                                       onChange={(e) => setDescription(e.target.value)}
                                       className="flex flex-col  text-sm"
                                       rows={3} label="Nova beleška"/>

                        {/*<Button color="secondary" onClick={onSubmit} endIcon={<img src="/next.svg"/>}><NextStep/></Button>*/}
                        <div className="w-[24px] h-[24px] flex items-center justify-center bg-secondary-action rounded-[6px]
                                        absolute bottom-[20px] right-[20px]
                                        hover:cursor-pointer hover:opacity-80"
                             onClick={onSubmit}>
                            <SvgArrowRightIcon width={16}/>
                        </div>
                    </div>
                </Modal.Body>
                {/*<Modal.Footer className="flex space-between" isValid={true}>*/}
                {/*    */}
                {/*    /!*<Button color="white" onClick={onModalClose}>Odustani</Button>*!/*/}
                {/*    /!*<Button color="secondary" onClick={onSubmit} endIcon={<img src="/next.svg"/>}>Sačuvaj</Button>*!/*/}
                {/*</Modal.Footer>*/}
            </Modal>
        </div>
    )
}

export default RecipeComment