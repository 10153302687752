import React, {useState} from "react"
import ThCheckbox from "./ThCheckbox";
import {NValue} from "./NutritionTable";
import {NCheckbox, Tr} from "./VitaminsTable";
import Checkbox from "./Checkbox";


const FattyAcid = ({nutrientValues, portionAmount, nutrientFlag, setNutrientFlag, onNutrientFlagChange}) => {

    const [selectAllChecked, setSelectAllChecked] = useState(false)

    const fattyAcid = nutrientValues?.nutrient_standard?.nutrient_fatty_acid
    const fattyAcidPortion = nutrientValues?.nutrient_portion?.nutrient_fatty_acid

    const handleSelectAllClick = () => {
        setNutrientFlag({
            ...nutrientFlag,
            "alpha_linolenic_acid": !selectAllChecked,
            "eicopentaenoic_acid": !selectAllChecked,
            "docosapentaenoic_acid": !selectAllChecked
        })

        setSelectAllChecked(!selectAllChecked)
    }

    return (
        <table className="bg-white rounded-[12px] w-full text-sm">
            <thead>
            <tr>
                <th></th>
                <th className="w-[100px]"></th>
                <th className="w-[100px]"></th>
                <th className="w-[100px]"></th>
                <th className="w-[100px]"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <div className="flex items-center hover:cursor-pointer hover:underline px-2" onClick={handleSelectAllClick}>
                    </div>
                </td>
                <ThCheckbox description="na" label="100g" checked={true} disabled={true}/>
                <ThCheckbox description="" label="NRV%" checked={false} disabled={true}/>
                <ThCheckbox description="Porcija" label={portionAmount ? portionAmount + 'g' : ''}
                            checked={nutrientFlag?.portion}
                            onChange={() => onNutrientFlagChange("portion")}/>
                <ThCheckbox description="" label="NRV%"
                            checked={false}
                            disabled={true}/>
            </tr>
            <Tr className="bg-section-header" selectable={false}>
                <td className="pl-4">
                    <div>Linoleinska kiselina (ALA)</div>
                </td>
                <td><NValue display={true} unit="mg" value={fattyAcid?.alpha_linolenic_acid}/></td>
                <td><NValue display={false} unit="µg" value={null}/></td>
                <td><NValue display={nutrientFlag.portion} unit="mg" value={fattyAcidPortion?.alpha_linolenic_acid}/></td>
                <td><NValue display={false} unit="µg" value={null}/></td>
            </Tr>

            <Tr selectable={false}>
                <td className="pl-4">
                    <div>Eikozapentaenoinska kiselina (EPA)</div>
                    </td>
                <td><NValue display={true} unit="mg" value={fattyAcid?.eicopentaenoic_acid}/></td>
                <td><NValue display={false} unit="µg" value={null}/></td>
                <td><NValue display={nutrientFlag.portion} unit="mg" value={fattyAcidPortion?.eicopentaenoic_acid}/></td>
                <td><NValue display={false} unit="µg" value={null}/></td>
            </Tr>


            <Tr className="bg-section-header" selectable={false}>
                <td className="pl-4">
                    <div>Dokozapentaenoinskakiselina (DPA)</div>
                </td>
                <td><NValue display={true} unit="mg" value={fattyAcid?.docosapentaenoic_acid}/></td>
                <td><NValue display={false} unit="µg" value={null}/></td>
                <td><NValue display={nutrientFlag.portion} unit="mg" value={fattyAcidPortion?.docosapentaenoic_acid}/></td>
                <td><NValue display={false} unit="µg" value={null}/></td>
            </Tr>
            </tbody>
        </table>
    )
}

export default FattyAcid