import * as React from "react";
const SvgCreateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <rect width={14} height={1.556} y={6.223} fill="#fff" rx={0.778} />
    <rect
      width={14}
      height={1.556}
      x={7.777}
      fill="#fff"
      rx={0.778}
      transform="rotate(90 7.777 0)"
    />
  </svg>
);
export default SvgCreateIcon;
