import {host, ingredients_url} from "../config/api";
import axios from "axios";

export const updateStatus = (requestId, status) => {
    return axios({
        method: 'post',
        url: `${host}/v1/nutrical-requests/${requestId}/update-status/${status}`
    })
}

