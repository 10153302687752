import React from "react";
import {CheckboxIcon} from '../icons';

const Checkmark = ({checked}) => {

    return (
        <div className={`w-[24px] h-[24px] rounded-[6px] ${checked ? 'bg-checked' : 'bg-unchecked'}  
                         flex items-center justify-center shrink-0 grow-0`}>
            {checked && <CheckboxIcon width={13} />}

        </div>
    )
}

export default Checkmark