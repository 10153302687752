import React from "react"
import PropTypes from "prop-types";

import "../css/status.css"
import {CheckCircleIcon, ClockIcon, InProgressIcon} from "../icons";

const Status = (props) => {

    let {status} = props;

    return (
        <div className="status">
            {status === "INIT" && (
                <div className="bg-status-init h-[22px]">
                    <ClockIcon/>
                    <span className="ml-[4px]">Na čekanju</span>
                </div>
            )}

            {status === "DONE" && (
                <div className="bg-status-completed h-[22px]">
                    <CheckCircleIcon/>
                    <span className="ml-[4px]">Završeno</span>
                </div>
            )}

            {status === "IN_PROGRESS" && (
                <div className="bg-status-in-progress h-[22px]">
                    <InProgressIcon/>
                    <span className="ml-[4px]">U radu</span>
                </div>
            )}

            {status === "CANCELED" && (
                <div className="canceled h-[22px]">
                    <img src="/canceled.svg" alt=""/>
                    <span className="ml-[4px]">Otkazan</span>
                </div>
            )}
        </div>
    )
}

Status.propTypes = {
    status: PropTypes.oneOf(["DONE", "IN_PROGRESS", "INIT"]).isRequired
}

export default Status;